import React, { useRef, useContext } from "react"
import { SectionalAlert } from "../../baseComponents"
import { LegalText, CompanyText, ApplicantText, DefaultText } from "./InvitationExpiredUtils"
import { configurationContext, myAccountContext, pathContext } from "../../../contexts/contexts"
import { LegalSection } from "../../LegalSection/LegalSection"
import { useParams } from "react-router-dom"
import PageTitleHook from '../../../utils/PageTitleHook/PageTitleHook'

export const InvitationExpired = () => {
  PageTitleHook('USCIS Online Account | Invitation expired')
  const { clearAlert } = useContext(myAccountContext)
  const { setUrl } = useContext(pathContext)
  const { configuration } = useContext(configurationContext)
  const { mobileView } = configuration
  const alertRef = useRef<HTMLDivElement>(null)
  const { text } = useParams()

  const textComponentFromTextParam = () => {
    switch (text) {
      case "applicant":
        return <ApplicantText />
      case "legal":
        return <LegalText />
      case "company":
        return <CompanyText />
      default:
        return <DefaultText />
    }
  }

  return (
    <div className="card mx-auto" data-testid="invitation-expired">
      <h1 className="text-2xl my-3 font-normal text-dhs_font_gray" data-testid="page-title">Invitation Expired</h1>
      <div data-testid="sectional-alert-container" ref={alertRef} tabIndex={0}>
        <SectionalAlert type="error" >
          Your invitation has expired.
        </SectionalAlert>
      </div>
      { textComponentFromTextParam() }
      <div className="flex flex-col">
        <p>
          Return to
          <button data-testid="sign-in-link" onClick={() => { clearAlert(); setUrl('/sign-in') }} aria-label='return to sign in' className='pl-1 text-dhs_light_blue hover:text-dhs_focus_blue hover:underline focus:ring-1 focus:ring-dhs_focus_blue no-underline font-bold mt-1 mr-auto' >Sign In</button>
        </p>
      </div>
      {mobileView ? null : <>
        <div className='bg-gray-200 h-px max-w-full mt-4' />
        <LegalSection />
      </>}
    </div>
  )
}