import React, { FunctionComponent } from "react"
import email from '../../../assets/images/footer/email.svg'
import facebook from '../../../assets/images/footer/facebook.svg'
import twitter from '../../../assets/images/footer/twitter.svg'
import X from '../../../assets/images/footer/x_logo.svg'
import linkedin from '../../../assets/images/footer/linkedin.svg'
import instagram from '../../../assets/images/footer/instagram.svg'
import youtube from '../../../assets/images/footer/youtube.svg'


export const FooterMidSocials: FunctionComponent = () => {
  return (
    <div className='ml-4 flex flex-col'>
      <div className='flex flex-row flex-wrap items-center space-x-2 sm:justify-end'>
        <div>
          <a aria-label="facebook" href="https://www.facebook.com/uscis" target="_blank" rel="noopener noreferrer">
            <img className="social-media-icon hover:bg-white" src={facebook} alt="Facebook Logo" />
          </a>
        </div>

        <div>
          <a aria-label="X" href="https://www.twitter.com/uscis" target="_blank" rel="noopener noreferrer">
            <img className="social-media-icon hover:bg-white" src={X} alt="X Logo" />
          </a>
        </div>

        <div>
          <a aria-label="youtube" href="https://www.youtube.com/uscis" target="_blank" rel="noopener noreferrer">
            <img className="social-media-icon hover:bg-white" src={youtube} alt="YouTube Logo" />
          </a>
        </div>

        <div>
          <a aria-label="instagram" href="https://www.instagram.com/uscis" target="_blank" rel="noopener noreferrer">
            <img className="social-media-icon hover:bg-white" src={instagram} alt="Instagram Logo" />
          </a>
        </div>

        <div>
          <a aria-label="linkedin" href="https://www.linkedin.com/company/uscis" target="_blank" rel="noopener noreferrer">
            <img className="social-media-icon hover:bg-white" src={linkedin} alt="LinkedIn Logo" />
          </a>
        </div>

        <div>
          <a aria-label="email" href="https://public.govdelivery.com/accounts/USDHSCIS/subscriber/new" target="_blank" rel="noopener noreferrer">
            <img className="social-media-icon hover:bg-white" src={email} alt="Email Logo" />
          </a>
        </div>
      </div>
      <div className='p-3 mb-6  sm:text-right sm:pr-0 text-dhs-blue hover:text-dhs-hyperlink-03 font-bold leading-7 underline underline-offset-2'>
        <a className='focus:ring-4 text-2xl focus:ring-dhs-blue-02 outline-none' href="https://www.uscis.gov/about-us/contact-us">Contact USCIS</a>
      </div>
    </div>
  )
}
export default FooterMidSocials