import React, { useContext, useRef, useState } from 'react'
import { myAccountContext, pathContext } from '../../../../contexts/contexts'
import PageTitleHook from '../../../../utils/PageTitleHook/PageTitleHook'
import { Button, ValidatedInput } from '../../../baseComponents'
import { useApiClient } from '../../../../hooks/useApiClient'


const ConfirmDelete = () => {
  PageTitleHook('USCIS Online Account | Delete account')
  const { setUrl } = useContext(pathContext)
  const { setUser } = useContext(myAccountContext)

  const [confirmInputVal, setconfirmInputVal] = useState('')
  const [confirmInputValidationMsg, setconfirmInputValidationMsg] = useState('')
  const confirmInputRef = useRef<HTMLInputElement>(null)

  const apiClient = useApiClient()

  const handleCancel = () => {
    apiClient.post('/account_deletion/cancel').then(res => {
      setUser(res.data)
    })
  }

  const handleSubmit = (e: any) => {

    if (confirmInputVal !== 'DELETE') {
      setconfirmInputValidationMsg('Please enter the text exactly as displayed to confirm.')
      if (confirmInputRef.current) {
        confirmInputRef.current!.focus()
        return
      }
    } else {
      setUrl('/delete-account/response')
    }
  }

  const handleEnterDown = (e: any) => {
    if (e.key === "Enter") {
      handleSubmit(e)
    }
  }

  const handleconfirmInput = (e: any) => {
    if (confirmInputValidationMsg !== '') setconfirmInputValidationMsg('')
    setconfirmInputVal(e.target.value)
  }

  return (
    <div className='card' data-testid="confirm-delete-container">
      <h1 className="card-header text-left ">Delete Account</h1>
      <p className='text-xl text-dhs-hyperlink-02 mb-10 ml-8'>Please Read This Information Carefully.</p>

      <div className="card-body p-0 flex flex-col space-y-6 text-md my-4 ">

        <p>By deleting your online account, you will no longer have access to any of the listed USCIS Services. Deleting your account is permanent and cannot be reversed. A deleted account cannot be reactivated.</p>
        <p className="font-bold">myUSCIS: This means you will lose the ability to:</p>
        <ul className='list-disc space-y-1 list-inside'>
          <li className='ml-5 '>Apply for certain immigration benefits <u>online</u></li>
          <li className='ml-5 '>Access your case filing history</li>
          <li className='ml-5 '>Manage completed and pending cases</li>
          <li className='ml-5 '>Track your case status</li>
          <li className='ml-5 '>Use secure message service</li>
        </ul>

        <p className="font-bold">FIRST: This means you will lose the ability to:</p>
        <ul className='list-disc space-y-1 list-inside'>
          <li className='ml-5 '>Submit Freedom of Information Act (FOIA)/Privacy Act requests online using FIRST. (Note: These requests may still be submitted by email and by using Form G-639, which is available on uscis.gov.)</li>
          <li className='ml-5 '>Manage received FOIA and/or Privacy Act responses</li>
          <li className='ml-5 '>Submit requests for your personal immigration record or another person’s immigration record</li>
        </ul>

        <p className="font-bold">myE-Verify: This means you will lose the ability to:</p>
        <ul className='list-disc space-y-1 list-inside'>
          <li className='ml-5 '>Confirm your employment eligibility records using Self-Check</li>
          <li className='ml-5 '>Track E-Verify or Self-Check case status and case history</li>
          <li className='ml-5 '>Protect your identity by locking your social security number in E-Verify</li>
          <li className='ml-5 '>Use myUploads to provide supporting documents to E-Verify</li>
        </ul>

        <p>Once you type “<b>DELETE</b>” to confirm your desire to delete your online account, your account and all associated data, if any, will be deleted. Your data can no longer be retrieved. The deletion of your account is final and cannot be reversed.</p>
        <p>Type the word “<b>DELETE</b>” in uppercase letters to confirm.</p>

        <ValidatedInput
          label=""
          msg={confirmInputValidationMsg}
          onChange={handleconfirmInput}
          value={confirmInputVal}
          type='text'
          ref={confirmInputRef}
          onKeyDown={handleEnterDown}
          arialabel={`Type the word “DELETE” in uppercase letters to confirm. ${confirmInputValidationMsg} ${confirmInputVal}`}
        />

      </div>
      <div className='button-container mt-4 px-0 flex flex-col space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0 '>
        <Button
          id="submit-button"
          data-testid="test-submit-button"
          text="Delete Account"
          className='max-w-full px-3 py-2 text-md'
          onClick={handleSubmit}
        />
        <Button
          id="cancel-btn"
          data-testid="test-cancel-btn"
          text="Cancel"
          type="cancel"
          className='max-w-full text-md'
          onClick={handleCancel}
        />
      </div>
    </div>
  )
}

export default ConfirmDelete
