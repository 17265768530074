import React from "react"

interface LogoutIconProps {
  className: string
}

const LogoutIcon = (props: LogoutIconProps) => {
  const { className } = props
  return <svg className={`${className} usa-icon`} aria-hidden="true" focusable="false" role="img">

    <use xlinkHref="#logout"></use>
    <symbol id="logout" viewBox="0 0 24 24" fill="currentColor">
      <path
        d="m17 7-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z"
      />
    </symbol>
  </svg>

}

export default LogoutIcon