import React from 'react'

export const ExpandIcon = (props: { color?: string; height?: string }) => {
  const { color, height = '24' } = props

  return (
    <svg
      data-testid="expand-small-icon"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height={height}
      viewBox="0 0 24 24"
    >
      <path
        d="M8.024,170.614l-7.861-7.8a.638.638,0,0,1,0-.9l1.052-1.052a.638.638,0,0,1,.9,0l6.358,6.294,6.358-6.294a.638.638,0,0,1,.9,0l1.052,1.052a.638.638,0,0,1,0,.9l-7.861,7.8A.638.638,0,0,1,8.024,170.614Z"
        transform="translate(0.025 -150)"
      />
    </svg>
  )
}
