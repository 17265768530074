import React, { FunctionComponent } from 'react'

import uscisfooterseal from '../../../assets/images/footer/uscis_logo.svg';

export const FooterMidLogo: FunctionComponent = () => {

  const imgSrc = uscisfooterseal
  const imgAlt = `U.S. Citizenship and Immigration Services - Upholding America's Promise logo`

  return (
      <img
      className='w-80 mt-7 sm:mt-0 sm:mb-1 sm:ml-16 sm:ml-8'
      src={imgSrc}
        alt={imgAlt}
      />
  );
};

export default FooterMidLogo;