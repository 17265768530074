import { useReducer } from "react"
import {
  initialState,
  UserProps,
  AlertProps,
} from "./reducer/myAccountInitialState"
import { initialPathState } from "./reducer/pathInitialState"
import { Routing } from "../src/components/Routing/Routing"
import { MyAccountReducer, actions } from "./reducer/myAccountReducer"
import { PathReducer, pathActions } from "./reducer/pathReducer"
import {
  ConfigurationReducer,
  configurationActions,
} from "./reducer/configurationReducer"
import {
  ConfigurationProps,
  initialConfigurationState,
} from "./reducer/configurationInitialState"
import {
  myAccountContext,
  pathContext,
  configurationContext,
} from "./contexts/contexts"

export function MyAccountProvider({ children }: any) {
  const [state, dispatch] = useReducer(MyAccountReducer, initialState)

  const myAccountValue = {
    user: state.user,
    setUser: (value: UserProps) => dispatch({ type: actions.SET_USER, value }),
    alert: state.alert,
    setAlert: (value: AlertProps) =>
      dispatch({ type: actions.SET_ALERT, value }),
    clearAlert: () => dispatch({ type: actions.CLEAR_ALERT }),
    clearPersistentAlert: () => dispatch({ type: actions.CLEAR_PERSISTENT_ALERT }),
    token: state.token,
    setToken: (value: string) => dispatch({ type: actions.SET_TOKEN, value }),
    showAnniversaryLogo: state.showAnniversaryLogo,
    setShowAnniversaryLogo: (value: boolean) =>
      dispatch({ type: actions.SET_SHOW_ANNIVERSARY_LOGO, value }),
  }

  return (
    <myAccountContext.Provider value={myAccountValue}>
      {children}
    </myAccountContext.Provider>
  )
}

export function PathProvider({ children }: any) {
  const [pathState, dispatchPath] = useReducer(PathReducer, initialPathState)

  const pathValue = {
    url: pathState.url,
    setUrl: (value: string) =>
      dispatchPath({ type: pathActions.SET_URL, value }),
    userState: pathState.userState,
    setUserState: (value: string) =>
      dispatchPath({ type: pathActions.SET_USER_STATE, value }),
    headerRef: pathState.headerRef,
    setHeaderRef: (value: any) =>
      dispatchPath({ type: pathActions.SET_HEADER_REF, value }),
  }

  return (
    <pathContext.Provider value={pathValue}>{children}</pathContext.Provider>
  )
}

export function ConfigurationProvider({ children }: any) {
  const [state, dispatch] = useReducer(
    ConfigurationReducer,
    initialConfigurationState
  )

  const configurationValue = {
    configuration: state.configuration,
    setConfiguration: (value: ConfigurationProps) =>
      dispatch({ type: configurationActions.SET_CONFIGURATION, value }),
  }

  return (
    <configurationContext.Provider value={configurationValue}>
      {children}
    </configurationContext.Provider>
  )
}

function App() {
  return (
    <ConfigurationProvider>
      <MyAccountProvider>
        <PathProvider>
          <Routing />
        </PathProvider>
      </MyAccountProvider>
    </ConfigurationProvider>
  )
}

export default App
