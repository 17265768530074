import React from "react";

export const PlaceHolder = () => {

    return (
        <div className="card bg-white" data-testid="place-holder-container">
          <h2 className="p-5">Temp Page</h2>
        </div>
    );
}

