export const loginGovFaqItems = [
  {
    title:
      'How does a Login.gov account work with a USCIS online account (myAccount)?',
    body: (
      <div className="pr-4 font-medium text-md ">
        If you have both a USCIS online account and a Login.gov account, you
        need to link the two accounts. You can use your Login.gov credentials to
        access your immigration information on the participating USCIS account
        platforms. You can always use your unique USCIS login credentials to
        access only your USCIS online account anytime you want – even after you
        have linked it to Login.gov.
      </div>
    ),
  },
  {
    title:
      'What if I already have a USCIS online account and want to use Login.gov?',
    body: (
      <div className="pr-4 font-medium text-md ">
        Once you link your existing USCIS online account to your new Login.gov
        account, you may access your USCIS online account by using your Login.gov
        access credentials. Login.gov is an optional way to sign on that allows
        people to easily access many government applications.
      </div>
    ),
  },
  {
    title: 'What if I don’t have a Login.gov account?',
    body: (
      <ul className="list-decimal px-8 font-medium text-md ">
        <li className="pb-2">
          Click the “Sign in with Login.gov” button on the USCIS Sign In page to
          be directed to the Login.gov website.
        </li>
        <li className="pb-2">
          Click “Create Account” and set up your account with Login.gov.
        </li>
        <li className="pb-2">
          Once you’ve finished creating your account with Login.gov, you will be
          directed back to USCIS to log in; using your email, password, and
          verification code.
        </li>
        <li className="pb-2">
          Then you will be automatically directed back to your USCIS
          application.
        </li>
        <li className="pb-2">
          You will only have to do this process once to link the two accounts.
          Moving forward, you can use the “Sign in with Login.gov” button for a
          quick sign-on experience.
        </li>
      </ul>
    ),
  },
  {
    title: 'What if I already have a Login.gov account?',
    body: (
      <ul className="list-decimal px-8 font-medium text-md ">
        <li className="py-2">
          Click the “Sign in with Login.gov” button on the Sign In page. From
          there you will login to Login.gov with your email, password, and
          security code.
        </li>
        <li className="py-2">
          After logging in to Login.gov, you will be directed back to the USCIS
          website to sign in. You will use your USCIS online account credentials
          (email, password, and OTP verification code) to successfully sign in.
        </li>
        <li className="py-2">
          Then you will be automatically directed back to your USCIS
          application.
        </li>
        <li className="py-2">
          You will only have to do this process once to link the two accounts.
          After that, you can use the “Sign in with Login.gov” button for a
          quick sign-on experience.
        </li>
      </ul>
    ),
  },
  {
    title:
      'What if I already have a Login.gov account, do I also have to create a USCIS online account to explore my immigration options?',
    body: (
      <div className="pr-4 font-medium text-md ">
        Yes, you must create an online account to file benefit requests and
        access certain online services. Your new USCIS online account can be
        linked to your existing Login.gov account. Login.gov is an optional sign
        on experience to allow people to easily sign into many government
        applications using the same access credentials.
      </div>
    ),
  },
  {
    title: 'What if I need help?',
    body: (
      <div className="pr-4 font-medium text-md ">
        If you need assistance accessing your Login.gov account or experience
        problems while creating a Login.gov account, please explore the
        <span className="px-1">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://login.gov/help/"
            className="nav-link px-0 no-underline hover:underline"
          >
            Login.gov help center
          </a>
        </span>
        prepared by the Login.gov team.
      </div>
    ),
  },
  {
    title: 'Where can I find more information?',
    body: (
      <div className="pr-4 font-medium text-md ">
        You can learn more about Login.gov on their
        <span className="pl-1">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://login.gov/what-is-login/"
            className="nav-link no-underline hover:underline px-0 "
          >
            website
          </a>
        </span>
        . You can learn more about a USCIS online account at this
        <span className="pl-1">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.uscis.gov/file-online/how-to-create-a-uscis-online-account"
            className="nav-link px-0 no-underline hover:underline"
          >
            page
          </a>
        </span>
        .
      </div>
    ),
  },
  {
    title:
      'Am I required to have a Login.gov account to use USCIS applications?',
    body: (
      <div className="pr-4 font-medium text-md ">
        No. You must create a USCIS online account to access USCIS applications.
        Login.gov is an optional sign-on experience to allow people to easily
        sign into many government applications.{' '}
      </div>
    ),
  },
]
