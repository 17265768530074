import React, { useContext } from 'react'
import { EverifyLogo } from "../../../assets/icons/everify_logo"
import { FirstLogo } from "../../../assets/icons/first_logo"
import { MyuscisLogo } from "../../../assets/icons/myuscis_logo"
import { pathContext } from "../../../contexts/contexts"

export interface DashboardCardProps {
  url: string
  buttonText: string
  description: string
  type: string
}

const logoMap = new Map()

logoMap.set('myUSCIS', <MyuscisLogo />)
logoMap.set('first', <FirstLogo />)
logoMap.set('myEverify', <EverifyLogo />)
logoMap.set('E-Verify+', "E-Verify+")
logoMap.set('Account RP', "Account RP")
logoMap.set('Edit myAccount', "Edit My Account Profile")

const DashboardCard = (props: DashboardCardProps) => {
  const { url, buttonText, description, type } = props
  const { setUrl } = useContext(pathContext)

  return (
    <div className="dashboard-card grow border-2" data-testid="dashboard-card">
      <div
        className="dashboard-card-header bg-dhs-hyperlink-02 text-white text-center items-center justify-center text-2xl font-bold h-28 md:h-full p-4 lg:p-8"
        aria-label={`${type} hyperlink`}
      >
        {logoMap.get(type)}
      </div>
      <div className="dashboard-card-body p-4 font-bold">
        <p className="mb-3">{description}</p>
        <div className="button-container">
          <button
            className="dashboard-link lg:bottom-4 lg:left-4"
            data-testid="dashboard-card-btn"
            aria-label={`${description} ${buttonText}`}
            onClick={() => setUrl(url)}
          >
            {buttonText}
          </button>
        </div>
      </div >
    </div >
  )
}

export default DashboardCard
