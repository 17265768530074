import React from 'react'

import { TableHeader, TableData } from './TableInterfaces'
interface TableProps {
  title: string;
  headers: Array<TableHeader>;
  data: Array<TableData>;
  striped?: boolean;
}

/*
 *  headers: an array of TableHeader objects for each column in the table
 *          title: String to display in header column
 *          key: string used to match the column header with the column data values to display
 *     data: an array of objects to display as rows in the table
 *          Each object must have keys corresponding to the key values defined in the headers
 *     
 */
export const Table = (props: TableProps) => {
  const { title, headers, data, striped } = props;

  return (
    <table className="table-fixed w-full" data-testid="table" title={title}>
      <thead >
        <tr data-testid="table-row">
          {headers.map((h, idx) => (
            <th scope="col" className={`border py-1 border-gray-300 ${!!h.width && h.width} break-words`} key={h.key} data-testid="table-column">
              {h.title}
            </th>
          )
          )}
        </tr>
      </thead>
      <tbody  >
        {data.map((row, rowIdx) => (
          <tr className={`${striped ? "even:bg-gray-100" : null}`} key={`table-row-${rowIdx}`} data-testid="table-row">
            {headers.map((h, colIdx) => (
              <td key={`table-cell-${h.key}`} className="py-1 px-2 border border-gray-300 break-words" data-testid={`row-${rowIdx}-col-${colIdx}`}>{row[`${h.key}`]}</td>
            ))
            }
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default Table
