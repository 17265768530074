import { useContext, useEffect, useRef } from "react"
import { GovBanner } from "../GovBanner"
import { HeaderBannerBar } from "../HeaderBannerBar"
import { configurationContext, pathContext } from "../../../contexts/contexts"
import { EnvironmentBanner } from "../EnvironmentBanner/EnvironmentBanner"
import { StatusBarSection } from "../StatusBarSection/StatusBarSection"


export const Header = () => {
  const { setHeaderRef, headerRef } = useContext(pathContext)
  const { configuration } = useContext(configurationContext)
  const { mobileView, appSettings } = configuration

  const topRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!headerRef) setHeaderRef(topRef)
  }, [setHeaderRef, headerRef])

  return (
    <div data-testid="header-container" ref={topRef}>
      {mobileView ? null : <GovBanner />}
      {appSettings.includes('StatusBarSectionEnabled') ? <StatusBarSection /> : null}
      <HeaderBannerBar />
      <EnvironmentBanner />
    </div>
  )
}