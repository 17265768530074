import React, { FunctionComponent } from "react";

export const FooterBottomIframe: FunctionComponent = () => {
  return (
    <div className='flex justify-center items-center sm:pt-6'>
      <section aria-label="National Terrorism Advisory System">
        <div>
          <iframe src="https://www.dhs.gov/ntas/" name="National Terrorism Advisory System" title="National Terrorism Advisory System" width="170" height="180">
          </iframe>
        </div>
      </section>
    </div>
  );
};

export default FooterBottomIframe;