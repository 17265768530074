import React, { FunctionComponent } from "react";
import dhsfootersealsmall from '../../../assets/images/footer/dhs_seal.svg';

export const OfficialLogoAndLink: FunctionComponent = () => {
  return (
      <div className='flex flex-col sm:flex-row my-3 sm:space-x-4 sm:w-full lg:pb-0'>
        <img
          className='h-14 w-14 mb-3 sm:mt-3 lg:my-0'
          src={dhsfootersealsmall}
          alt="U.S. Department of Homeland Security Seal, U.S. Citizenship and Immigration Services"
        />
        <div className='flex flex-col text-white text-base sm:w-max lg:whitespace-nowrap'>
          <p className='font-light'>USCIS.gov</p>
          <p className='font-bold sm:break-words sm:whitespace-normal'>An official website of the <a className="underline underline-offset-2 text-dhs-gray-00  hover:text-white outline-none focus:ring focus:ring-dhs-blue-02" href="https://www.dhs.gov/">U.S. Department of Homeland Security</a></p>
        </div>
      </div>
  );
};

export default OfficialLogoAndLink;