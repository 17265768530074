import React, { FunctionComponent } from 'react'
import FocusTrap from 'focus-trap-react'
import Button from '../Button/Button'

type ModalProps = {
  title?: string
  onCancel?: () => void
  onOk?: () => void
  visible?: boolean
  info?: boolean
  okButtonText?: string
  cancelButtonText?: string
  loadingModal?: boolean
  hideCloseButton?: boolean
  hideCancelButton?: boolean
  hideOkButton?: boolean
  zIndex?: string
  stylingOptions?: string
  stylingOkButton?: string
  justifyContents?: string
}

const xButtonSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    fill="#006699"
    className="bi bi-x"
    viewBox="0 0 16 16"
  >
    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
  </svg>
)
export const Modal: FunctionComponent<ModalProps> = ({
  title = '',
  visible = false,
  onCancel,
  onOk,
  info = false,
  children,
  loadingModal = false,
  okButtonText = 'OK',
  cancelButtonText = 'Cancel',
  hideCloseButton = false,
  hideCancelButton = false,
  hideOkButton = false,
  zIndex = 'z-20',
  stylingOptions = '',
  stylingOkButton = '',
  justifyContents = 'justify-start',
}) => {
  const normalOverflow = ''
  const outerElementRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden'
      outerElementRef.current!.focus()
    } else {
      document.body.style.overflow = normalOverflow
    }
  }, [visible, normalOverflow])

  if (!visible) return null

  const isInfoModal = info === true
  const divStyle = `fixed p-4 inset-0 bg-gray-600 bg-opacity-50 backdrop-blur flex justify-center items-center ${zIndex} overflow-auto`
  return !isInfoModal ? ( // modal with title
    <FocusTrap>
      <div
        id="modal-container"
        data-testid="modal-container"
        className={divStyle}
        ref={outerElementRef}
        tabIndex={0}
      >
        <div className="card relative">
          {title !== '' && (
            <div className="card-header flex justify-between">
              <h2 data-testid="modal-title">{title}</h2>
            </div>
          )}
          <div data-testid="modal-body-content" className="card-body">
            {children}
          </div>
          <div className={`pt-6 pb-4 text-xl flex ${justifyContents} gap-2`}>
            {!hideOkButton && (
              <Button
                className={`${stylingOkButton}`}
                type="primary"
                id="modal-ok-btn"
                text={okButtonText}
                onClick={onOk}
              />
            )}
            {!hideCancelButton && (
              <Button
                type="cancel"
                id="modal-cancel-btn"
                text={cancelButtonText}
                onClick={onCancel}
              />
            )}
            {!hideCloseButton && (
              <button
                className="absolute top-0 right-0 m-1 outline-none focus:outline-5 focus:ring-4  focus:ring-dhs-blue-05"
                onClick={onCancel}
                title="close modal"
                data-testid="close-btn"
              >
                {xButtonSvg}
              </button>
            )}
          </div>
        </div>
      </div>
    </FocusTrap>
  ) : !loadingModal ? ( // info modal
    <FocusTrap>
      <div
        id="info-modal-container"
        data-testid="info-modal-container"
        className={divStyle}
        ref={outerElementRef}
        tabIndex={0}
      >
        <div className={`${stylingOptions || 'card relative'}`}>
          {title !== '' && (
            <div className="card-header flex justify-between">
              <h2 data-testid="modal-title">{title}</h2>
            </div>
          )}
          <div data-testid="modal-body-content" className="card-body">
            {children}
          </div>
          <div className={`p-3y text-xl flex ${justifyContents}`}>
            <Button
              className={`mx-2 ${stylingOkButton}`}
              type="primary"
              id="info-ok-btn"
              text={`${okButtonText || `Done`}`}
              onClick={onOk}
            />
          </div>
        </div>
      </div>
    </FocusTrap>
  ) : (
    // spinner loading modal
    <div
      id="spinner-modal-container"
      data-testid="spinner-modal-container"
      className={divStyle}
      ref={outerElementRef}
      tabIndex={0}
    >
      <div className="card lg:w-1/3">
        <div data-testid="modal-body-content" className="card-body">
          {children}
        </div>
      </div>
    </div>
  )
}
