import React from 'react'
export const NewPageIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18.667" height="16" viewBox="0 0 18.667 16">
            <title>open in new tab</title>
            <path id="path3029" d="M14.667-1526.333V-1523a2.89,2.89,0,0,1-.88,2.12,2.889,2.889,0,0,1-2.12.88H3a2.889,2.889,0,0,1-2.12-.88A2.89,2.89,0,0,1,0-1523v-8.667a2.89,2.89,0,0,1,.88-2.12,2.89,2.89,0,0,1,2.12-.88h7.333a.325.325,0,0,1,.24.094.325.325,0,0,1,.094.24v.667a.324.324,0,0,1-.094.24.324.324,0,0,1-.24.094H3a1.605,1.605,0,0,0-1.177.49,1.6,1.6,0,0,0-.49,1.177V-1523a1.6,1.6,0,0,0,.49,1.177,1.6,1.6,0,0,0,1.177.49h8.667a1.6,1.6,0,0,0,1.177-.49,1.6,1.6,0,0,0,.49-1.177v-3.333a.324.324,0,0,1,.094-.24.325.325,0,0,1,.24-.094h.667a.325.325,0,0,1,.24.094A.324.324,0,0,1,14.667-1526.333Zm4-9V-1530a.641.641,0,0,1-.2.469.641.641,0,0,1-.469.2.641.641,0,0,1-.469-.2l-1.833-1.833-6.792,6.792a.329.329,0,0,1-.24.1.329.329,0,0,1-.24-.1L7.24-1525.76a.329.329,0,0,1-.1-.24.329.329,0,0,1,.1-.24l6.792-6.792-1.833-1.833a.641.641,0,0,1-.2-.469.641.641,0,0,1,.2-.469.641.641,0,0,1,.469-.2H18a.641.641,0,0,1,.469.2A.641.641,0,0,1,18.667-1535.333Z" transform="translate(0 1536)" fill="currentColor" />
        </svg>


    )
}
