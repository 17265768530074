import React from 'react'

export const FloatingButtonIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"  width="74" height="74" viewBox="0 0 74 74">
        <defs>
            <filter id="Ellipse_4" x="0" y="0" width="74" height="74" filterUnits="userSpaceOnUse">
            <feOffset dy="3" />
            <feGaussianBlur stdDeviation="3" result="blur"/>
            <feFlood floodOpacity="0.161"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
            </filter>
        </defs>
        <g id="Button_-_Floating" data-name="Button - Floating" transform="translate(9 6)">
            <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Ellipse_4)">
            <circle id="Ellipse_4-2" data-name="Ellipse 4" cx="28" cy="28" r="28" transform="translate(9 6)" fill="#069"/>
            </g>
            <g id="Group_211615" data-name="Group 211615" transform="translate(0.25 -1.5)">
            <path id="Path_276756" data-name="Path 276756" d="M0,0V34" transform="translate(27.851 11)" fill="none" stroke="#fff" strokeWidth="4"/>
            <g id="Group_211614" data-name="Group 211614">
                <line id="Line_112" data-name="Line 112" x2="15.75" y2="14.65" transform="translate(13.5 31.7)" fill="none" stroke="#fff" strokeWidth="4"/>
                <line id="Line_115" data-name="Line 115" x1="15.75" y2="14.65" transform="translate(26.5 31.7)" fill="none" stroke="#fff" strokeWidth="4"/>
            </g>
            </g>
        </g>
        </svg>
   )
}