import React, { useEffect, useState, useRef, useContext } from "react"

import { useApiClient } from '../../../hooks/useApiClient'
import { Button, SectionalAlert, ValidatedInput } from "../../baseComponents"
import { myAccountContext, pathContext } from '../../../contexts/contexts'
import { QuestionProps, defaultUser } from "../../../reducer/myAccountInitialState"
import PageTitleHook from "../../../utils/PageTitleHook/PageTitleHook"
import { exceededMaximumAttempts } from "../../../utils/utils"

interface SecurityAnswerProps {
  id: number | null
  text: string
}

const defaultAnswer = {
  id: null,
  text: ''
}

export const AnswerSecurityQuestions = () => {
  PageTitleHook('USCIS Online Account | Security question')
  const { setUser, alert, setAlert, clearAlert, user, token } = useContext(myAccountContext)
  const { setUrl, setUserState } = useContext(pathContext)
  const [questions, setQuestions] = useState<Array<QuestionProps>>([])
  const [answers, setAnswers] = useState<Array<SecurityAnswerProps>>([defaultAnswer, defaultAnswer, defaultAnswer])
  const [errorMsgs, setErrorMsgs] = useState<Array<string>>(['', '', ''])

  const alertRef = useRef<HTMLDivElement>(null)

  const apiClient = useApiClient()

  useEffect(() => {
    if (user.questions && user.questions.length > 0) {
      setQuestions(user.questions)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (alert.message !== '') {
      alertRef.current!.focus()
    }
  }, [alert])

  const handleSubmit = (e: any) => {
    if (alert.message !== '') clearAlert()
    const errs = answers.map((ans) =>
      ans.text.trim() === '' ? 'Security Answer cannot be blank' : ''
    )
    setErrorMsgs(errs)
    if (errs.some((text) => text !== '')) return // check for non-empty error message

    const body = createBody()

    apiClient
      .post(
        '/password_reset/verify_security_answers',
        { token: token, answers: body }
      )
      .then(res => {
        setUser(res.data)
        setUrl('/create-password')
      })
      .catch(err => {
        const data = err.response.data
        if (!!data.questions && Object.values(data.questions).length > 0) {
          setAlert({
            type: 'error',
            message: 'Sorry, your answers do not match'
          })
          setAnswers([defaultAnswer, defaultAnswer, defaultAnswer])
          setQuestions(Object.values(data.questions))
        } else if (data.error !== '') {
          setAlert({
            type: 'error',
            message: exceededMaximumAttempts
          })
          setUserState('')
          setUser(defaultUser)
          setUrl('/sign-in')
        } else {
          console.log('should never reach here')
        }
      }
      )
  }

  const createBody = () => {
    let body = {}
    answers.forEach((ans, idx) => {
      // body = { ...body, [idx]: Object.assign({
      //    "security_question_id": ans.id,
      //     "text": ans.text }) }
      body = {
        ...body, [idx]: {
          security_question_id: ans.id,
          text: ans.text
        }
      }
    })
    return body
  }

  const handleChange = (text: string, id: number, index: number) => {
    if (alert.message !== '') clearAlert()
    if (errorMsgs[index] !== '') setErrorMsgs(errorMsgs.map((item, idx) => (idx === index) ? '' : item))

    const answersCopy = [...answers]
    answersCopy[index] = { text: text, id: id }
    setAnswers(answersCopy)
  }

  return (
    <div className="card mx-auto" data-testid="answer-security-questions-container">
      <h1 className="py-1 text-2xl"> Security Questions</h1>
      {alert.message !== '' &&
        <div className='py-2' ref={alertRef} tabIndex={0} data-testid='sectional-alert'>
          <SectionalAlert type={alert.type}>
            {alert.message}
          </SectionalAlert>
        </div>
      }
      <p className="text-md py-4">Please answer your personal security questions to verify your identity.</p>
      {questions?.map((item, index) => (
        <div key={`question-${index}`}>
          <h2 className="pt-2 text-xl">Question {index + 1} of {questions.length}</h2>

          <div data-testid="questions">
            <h3 className='text-md py-4' data-testid="answer-label">
              {item.question}
              <span className='text-dhs-error-red pl-1'>*</span>
            </h3>
            <h3 className='text-md pt-2' data-testid="answer-label">
              {`Security Answer ${index + 1}`}
              <span className='text-dhs-error-red pl-1'>*</span>
            </h3>
            <ValidatedInput
              label={`Security Answer ${index + 1} - ${item.question}`}
              required
              msg={errorMsgs[index]}
              onChange={(e) => handleChange(e.target.value, item.id, index)}
              value={answers[index].text || ''}
              type='text'
              hideLabel
            />
          </div>
          <hr className="py-2 w-full" />
        </div>
      ))}
      <div className="button-container pt-2">
        <Button
          id="answer-security-questions-btn"
          data-testid="answer-security-questions-btn"
          text="Submit"
          onClick={handleSubmit}
        />
      </div>
    </div>
  )
}
