import React, { useEffect, useState, useContext } from 'react'
import { Button } from '../../baseComponents'
import { myAccountContext } from "../../../contexts/contexts"
import PageTitleHook from '../../../utils/PageTitleHook/PageTitleHook'
import { useSignOut } from '../../../hooks/hooks'

const SoftLocked = () => {
  PageTitleHook('USCIS Online Account | Account locked')
  const { user, setUser } = useContext(myAccountContext)

  const handleSignOut = useSignOut()
  const [lockedTimeLeft, setLockedTimeLeft] = useState<number | undefined>()

  useEffect(() => {
    setLockedTimeLeft(user.seconds_remaining)
  }, [user.seconds_remaining])

  let seconds = lockedTimeLeft ? lockedTimeLeft % 60 : 0
  let minutes = lockedTimeLeft ? (lockedTimeLeft - seconds) / 60 : 0

  const handleReturnToSignIn = () => {
    setUser({ ...user, authentication_state: '' })
    handleSignOut()
  }

  useEffect(() => {
    if (lockedTimeLeft === 0) {
      handleReturnToSignIn()
    }

    if (!lockedTimeLeft) {
      return
    }

    const timeout = setTimeout(() => {
      setLockedTimeLeft(lockedTimeLeft - 1)
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lockedTimeLeft])

  let timeLeftText = ''

  if (minutes || seconds) {
    timeLeftText += ' in '
  }

  if (minutes) {
    timeLeftText += `${minutes} minute`
  }

  if (minutes > 1) {
    timeLeftText += 's'
  }

  if (minutes && seconds) {
    timeLeftText += ' and '
  }

  if (seconds) {
    timeLeftText += `${seconds} second`
  }

  if (seconds > 1) {
    timeLeftText += 's'
  }

  return (
    <div className="card mx-auto" data-testid="SoftLocked-container">
      <h1 className='text-2xl my-3 font-normal text-dhs_font_gray'>Account Locked</h1>
      <p className='text-paragraph'>Your account is temporarily locked because you have entered the verification code incorrectly too many times.</p>
      <p role='timer' className='text-paragraph'  >Please try again<span data-testid='time-left-text'>{timeLeftText}</span>.</p>
      <div className="button-container">
        <Button id='return-to-sign-in-btn' text='Return to Sign In' onClick={handleReturnToSignIn}></Button>
      </div>
    </div>
  )
}
export default SoftLocked
