import React from "react";

export const ReturnToTop = () => {
  return (
    <div className='max-w-screen-lg lg:mx-auto'>
      <div className='pb-4 pt-12'>
        <a className='text-dhs-blue-01 pl-12 hover:text-dhs-hyperlink-02 underline underline-offset-2 focus:ring-4 focus:ring-dhs-blue-02 outline-none' href="#maincontent">Return to top</a>
      </div>
    </div>
  );
};

export default ReturnToTop; 