import PageTitleHook from '../../../../utils/PageTitleHook/PageTitleHook'
import { pathContext } from '../../../../contexts/contexts'
import { useContext } from 'react'

const DeleteAccountSuccess = () => {
  PageTitleHook('USCIS Online Account | Delete account response')
  const { setUrl } = useContext(pathContext)

  return (
    <div className='card' data-testid="delete-account-success-container">
      <h1 className="card-header text-left ">Your Account was Successfully Deleted</h1>
      <div className='mx-16 border border-y-2 border-gray-200 opacity-30'></div>
      <div className="card-body p-0 flex flex-col space-y-6 text-md my-4 mb-8">
        <p>You have successfully deleted your USCIS online account. To access USCIS online services, you need to create a new account. You may use the same email address as the deleted account if desired.</p>
      </div>
      <div className='button-container mt-4 px-0 flex flex-col space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0 '>
        <button
          id='uscisgov-button'
          data-testid='uscisgov-button'
          className="dashboard-link"
          aria-label={`Go to USCIS.gov button`}
          onClick={() => {
            setUrl('https://www.uscis.gov/')
          }}
        >
          Go to USCIS.gov
        </button>
      </div>
    </div>
  )
}
export default DeleteAccountSuccess