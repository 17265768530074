import React from 'react'

export const EverifyLogo = () => {
    return (
        <svg role='img' aria-label='myEverify logo' id="my-E-Verify_logo_1_" data-name="my-E-Verify_logo (1)" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="138.615" height="26.246" viewBox="0 0 138.615 26.246">
            <defs>
                <linearGradient id="linear-gradient" x1="-0.002" y1="14.299" x2="1" y2="14.299" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#fff"/>
                <stop offset="1" stopColor="#762123" stopOpacity="0"/>
                </linearGradient>
            </defs>
            <path id="Path_276757" data-name="Path 276757" d="M200.9,37.137h-4V36.2h4Z" transform="translate(-140.994 -25.917)" fill="#fff"/>
            <path id="Path_276758" data-name="Path 276758" d="M140.5,74.509h0c0,.824,2.385.2,2.385.937V73.6C141.778,73.685,140.784,73.884,140.5,74.509Z" transform="translate(-100.608 -52.694)" fill="#666"/>
            <path id="Path_276759" data-name="Path 276759" d="M140.5,6.753V2.009c.483-.909,2.527-.909,4.373-.909h10.733V5.844H144.873C143.311,5.844,140.954,5.73,140.5,6.753Zm0,11.135v4.744c.454-1.023,2.811-.909,4.373-.909h10.733V16.95H144.873C143.027,16.95,140.983,16.95,140.5,17.887Zm0-7.953v4.744c.454-1.023,2.811-.909,4.373-.909h8.972V9.025h-8.972C143.027,9.025,140.983,9.025,140.5,9.934Z" transform="translate(-100.608 -0.788)" fill="#fff"/>
            <path id="Path_276760" data-name="Path 276760" d="M147.229,1.1h-2.357c-1.846,0-3.89,0-4.373.909V22.631c.454-1.023,2.811-.909,4.373-.909h2.357Z" transform="translate(-100.608 -0.788)" fill="url(#linear-gradient)"/>
            <g id="Group_211628" data-name="Group 211628" transform="translate(29.387 3.068)">
                <rect id="Rectangle_1641" data-name="Rectangle 1641" width="10.505" height="6.192" transform="translate(0 12.044)" fill="#fff"/>
                <path id="Path_276761" data-name="Path 276761" d="M114.005,10.8c-.681.028-1.391.028-1.988.028H103.5V23.554h8.518c.681,0,1.363,0,1.988-.057Z" transform="translate(-103.5 -10.8)" fill="#c1d2e1"/>
                <path id="Path_276762" data-name="Path 276762" d="M107.894,15.3l.227.71h.767l-.625.454.227.71-.6-.454-.6.454.227-.71-.625-.454h.767Zm3.123.71h-.767l.625.454-.227.71.6-.454.6.454-.227-.71.625-.454h-.767l-.227-.71Zm3.322,0h-.767l.625.454-.227.71.6-.454.6.454-.227-.71.625-.454h-.767l-.227-.71Zm-6.672,2.841H106.9l.625.454-.227.71.6-.454.6.454-.227-.71.625-.454h-.767l-.227-.71Zm3.35,0h-.767l.625.454-.227.71.6-.454.6.454-.227-.71.625-.454h-.767l-.227-.71Zm3.322,0h-.767l.625.454-.227.71.6-.454.6.454-.227-.71.625-.454h-.767l-.227-.71Zm-6.672,2.812H106.9l.625.454-.227.71.6-.454.6.454-.227-.71.625-.454h-.767l-.227-.71Zm3.35,0h-.767l.625.454-.227.71.6-.454.6.454-.227-.71.625-.454h-.767l-.227-.71Zm3.322,0h-.767l.625.454-.227.71.6-.454.6.454-.227-.71.625-.454h-.767l-.227-.71Zm-6.672,2.812H106.9l.625.454-.227.71.6-.454.6.454-.227-.71.625-.454h-.767l-.227-.71Zm3.35,0h-.767l.625.454-.227.71.6-.454.6.454-.227-.71.625-.454h-.767l-.227-.71Zm3.322,0h-.767l.625.454-.227.71.6-.454.6.454-.227-.71.625-.454h-.767l-.227-.71Z" transform="translate(-105.935 -14.022)" fill="#fff"/>
                <g id="Group_211627" data-name="Group 211627" transform="translate(0 15.85)">
                <path id="Path_276763" data-name="Path 276763" d="M114.005,69.526V66.6c-.681.028-1.391.028-1.988.028H103.5v4.744h8.518c1.846,0,3.89,0,4.373-.909,0-.767-2.385-.142-2.385-.937Z" transform="translate(-103.5 -66.6)" fill="#e5d3d5"/>
                </g>
            </g>
            <g id="Group_211629" data-name="Group 211629" transform="translate(59.427)">
                <path id="Path_276764" data-name="Path 276764" d="M285.951,28.938c-.937,3.721-4.231,5.425-7.865,5.425a9.041,9.041,0,0,1-6.19-2.017,7.577,7.577,0,0,1-2.5-5.766,8.487,8.487,0,0,1,2.215-5.795,8.577,8.577,0,0,1,6.1-2.386c5.111,0,8.234,3.3,8.234,8.351v.824H275.645c0,1.647.539,2.841,2.357,2.841a2.068,2.068,0,0,0,2.357-1.875Zm-5.991-4.346c0-1.505-.483-2.642-2.158-2.642-1.562,0-2.129,1.221-2.129,2.642Z" transform="translate(-252.334 -13.173)" fill="#fff"/>
                <path id="Path_276765" data-name="Path 276765" d="M336.311,21.932c.511-2.13,1.618-3.636,4-3.465l.823.057v5.369A4.973,4.973,0,0,0,340,23.75,2.542,2.542,0,0,0,337.56,24.8a5.412,5.412,0,0,0-.426,2.642v6.675H331.2V18.779h5.054v3.153h.057Z" transform="translate(-296.589 -13.212)" fill="#fff"/>
                <rect id="Rectangle_1642" data-name="Rectangle 1642" width="5.934" height="2.67" transform="translate(45.912 1.42)" fill="#fff"/>
                <rect id="Rectangle_1643" data-name="Rectangle 1643" width="5.934" height="15.367" transform="translate(45.912 5.567)" fill="#fff"/>
                <path id="Path_276766" data-name="Path 276766" d="M398.1,5.851a6.1,6.1,0,0,1,1.107-4.176C400.4.227,402.245,0,404.034,0a33.325,33.325,0,0,1,4.429.4l-1.675,3.579a13.105,13.105,0,0,0-1.42-.057c-1.079,0-1.533.71-1.391,2.159h2.385l1.391,3.778h-3.719v11.05H398.1Z" transform="translate(-344.494 0)" fill="#fff"/>
                <path id="Path_276767" data-name="Path 276767" d="M222.389,21.694h-6.445L209.3,1.1h5.991l4.174,13.436,3.8-13.436h5.537Z" transform="translate(-209.3 -0.788)" fill="#fff"/>
                <path id="Path_276768" data-name="Path 276768" d="M440.86,21.4l-2.5,7.9-2.924-7.9H429.9l5.168,13.379a4.865,4.865,0,0,1,.4,1.392c0,1.193-1.789,1.165-2.612,1.165a18.4,18.4,0,0,1-2.158-.17v4.261c1.192.114,2.129.142,3.038.142,3.436,0,4.969-1.193,6.1-4.374L445.545,21.4Z" transform="translate(-367.265 -15.321)" fill="#fff"/>
            </g>
            <rect id="Rectangle_1644" data-name="Rectangle 1644" width="3.152" height="3.153" transform="translate(135.463 18.378)" fill="none"/>
            <g id="Group_211630" data-name="Group 211630" transform="translate(135.605 18.435)">
                <path id="Path_276769" data-name="Path 276769" d="M479.417,65.809a.948.948,0,0,1-.256.653.9.9,0,0,1-.653.256.947.947,0,0,1-.653-.256.9.9,0,0,1-.256-.653.948.948,0,0,1,.256-.653.9.9,0,0,1,.653-.256.947.947,0,0,1,.653.256A.9.9,0,0,1,479.417,65.809Zm-.085,0a.793.793,0,0,0-.227-.568.729.729,0,0,0-.568-.227.793.793,0,0,0-.568.227.73.73,0,0,0-.227.568.793.793,0,0,0,.227.568.729.729,0,0,0,.568.227.793.793,0,0,0,.568-.227A.9.9,0,0,0,479.332,65.809Zm-1.164-.54h.369a.377.377,0,0,1,.284.085.275.275,0,0,1,0,.4,1.387,1.387,0,0,1-.2.085c.028.028.057.028.085.057s.028.057.085.085a.392.392,0,0,0,.085.114l.114.17h-.17l-.085-.17c-.085-.114-.142-.2-.17-.227s-.114-.028-.2-.028h-.085v.426h-.114Zm.142.114v.341h.114a.712.712,0,0,0,.284-.028c.028,0,.057-.028.085-.057a.1.1,0,0,0,.028-.085.148.148,0,0,0-.057-.114.409.409,0,0,0-.255-.057Z" transform="translate(-477.6 -64.9)" fill="#005287"/>
            </g>
            <g id="Group_211631" data-name="Group 211631" transform="translate(0 11.39)">
                <path id="Path_276770" data-name="Path 276770" d="M0,40.327H2.47v1.363A3.923,3.923,0,0,1,5.622,40.1a3.477,3.477,0,0,1,1.675.4,3.136,3.136,0,0,1,1.164,1.193A4.546,4.546,0,0,1,9.881,40.5a3.613,3.613,0,0,1,1.647-.4,3.526,3.526,0,0,1,1.874.454,2.633,2.633,0,0,1,1.136,1.335,6.065,6.065,0,0,1,.284,2.1v6.448H12.152V44.645a4.017,4.017,0,0,0-.284-1.932,1.291,1.291,0,0,0-1.136-.568,1.784,1.784,0,0,0-1.051.341,2,2,0,0,0-.71.994,7.178,7.178,0,0,0-.227,2.074v4.829H6.076V44.872a8.172,8.172,0,0,0-.142-1.9,1.165,1.165,0,0,0-.454-.625,1.416,1.416,0,0,0-.823-.2,1.892,1.892,0,0,0-1.107.341,1.774,1.774,0,0,0-.71.966,7.042,7.042,0,0,0-.227,2.074v4.886H0Z" transform="translate(0 -40.1)" fill="#fff"/>
                <path id="Path_276771" data-name="Path 276771" d="M57.1,40.9h2.839l2.413,7.158L64.709,40.9h2.754l-3.549,9.686-.625,1.761a5.2,5.2,0,0,1-.681,1.335,3.8,3.8,0,0,1-.738.767,3.38,3.38,0,0,1-1.022.454,4.967,4.967,0,0,1-1.363.17,6.607,6.607,0,0,1-1.5-.17l-.227-2.1a5.684,5.684,0,0,0,1.136.114,1.6,1.6,0,0,0,1.363-.54,4.246,4.246,0,0,0,.681-1.392Z" transform="translate(-40.888 -40.673)" fill="#fff"/>
            </g>
        </svg>

    )
} 

