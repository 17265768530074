import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useApiClient } from '../../../hooks/useApiClient'
import { FloatingButtonIcon } from '../../../assets/icons'
import { Button } from '../../baseComponents'
import { myAccountContext, pathContext } from '../../../contexts/contexts'
import '../TermsOfUse/termsofuse.css'
import './CreateAccountTos.css'
import PageTitleHook from '../../../utils/PageTitleHook/PageTitleHook'

const CreateAccountTos = () => {
  PageTitleHook('USCIS Online Account | Legal')
  const { setUrl } = useContext(pathContext)
  const { setUser } = useContext(myAccountContext)

  const scrollArrowRef = useRef<HTMLDivElement>(null)
  const agreeButtonRef = useRef<HTMLButtonElement>(null)
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const [showBtn, setShowBtn] = useState<boolean>(true)
  const [submitButtonDisabled, setSubmitButtonDisabled ] = useState<boolean>(false)

  const apiClient = useApiClient()

  var scrollingInterval: any = setInterval(() => {})

  const handleSubmit = (e: any) => {
    const url = window.location.origin + '/v1/users/accept_terms'
    setSubmitButtonDisabled(true)
    apiClient
      .post(url)
      .then((res) => {
        if (res.status === 200) {
          setUser(res.data)
          if (
            res.data['authentication_state'] === 'awaiting_password_creation'
          ) {
            setUrl('/create-password')
          }
          if (res.data['authentication_state'] === 'fully_signed_in') {
            setUrl('/dashboard')
          }
        }
      })
      .catch((err) => {
        console.log(err.response)
      })
  }

  const handleKeyDown = (e: any) => {
    if (e.repeat) return
    if (e.key === 'Enter' || e.key === ' ') {
      scrollingInterval = setInterval(scrollDown, 100)
    }
  }

  const handleKeyUp = (e: any) => {
    if (e.key === 'Enter' || e.key === ' ') {
      stopScroll()
    }
  }

  const startScrollDown = () => {
    scrollingInterval = setInterval(scrollDown, 100)
  }

  const scrollDown = () => {
    if (isBottom(document.getElementById('container'))) {
      stopScroll()
    } else {
      window.scrollBy({
        top: 100,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  const stopScroll = () => {
    clearInterval(scrollingInterval)
  }

  const isBottom = (el: HTMLElement | null) => {
    return el ? el.getBoundingClientRect().bottom <= window.innerHeight : false
  }

  const trackScrolling = useCallback(() => {
    const wrappedElement = document.getElementById('container')
    if (isBottom(wrappedElement)) {
      setShowBtn(false)
    } else {
      setShowBtn(true)
    }
  }, [])

  const handleClickPageDown = () => {
    window.scrollBy({
      top: 100,
      left: 0,
      behavior: 'smooth',
    })
  }  

  useEffect(() => {
    window.addEventListener('scroll', trackScrolling)
    return () => window.removeEventListener('scroll', trackScrolling)
  }, [trackScrolling])

  return (
    <div
      id="container"
      className="card"
      ref={scrollContainerRef}
      data-testid="create-account-tos-container"
    >
      <div>
        {showBtn && (
          <div
            ref={scrollArrowRef}
            data-testid="scroll-btn-container"
            className="relative"
            tabIndex={0}
          >
            <div className="absolute right-10 sm:right-4 top-96 z-10">
              <button
                data-testid="test-scroll-btn"
                aria-label="Scroll Button"
                className="fixed"
                onClick={handleClickPageDown}
                onMouseDown={startScrollDown}
                onMouseUp={stopScroll}
                onMouseOut={stopScroll}
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
              >
                <FloatingButtonIcon />
              </button>
            </div>
          </div>
        )}
        <h1 className="text-3xl">Terms of Use</h1>

        <h2
          tabIndex={0}
          className="terms-heading text-2xl text-dhs-blue"
          id="tos-tos"
        >
          U.S. Citizenship and Immigration Services Online Electronic
          Immigration System
        </h2>
        <div>
          <p className="terms-paragraph">
            This document contains the <b>Terms</b> of Use (“<b>Terms</b>”) for
            the U.S. Citizenship and Immigration Services (USCIS) online
            electronic immigration system accessed using established portals,
            such as myAccount access page, or its successor. You should read
            these <b>Terms</b> in their entirety and acknowledge that by
            creating an account or accessing or attempting to access systems and
            services available online through myAccount, or its successor, that
            you have read, understand, and agree to comply with these{' '}
            <b>Terms</b> in order to access and use any or all of these systems.
            The <b>Terms</b> apply to every individual and user, including
            attorneys and accredited representatives acting on behalf of a
            business, corporation, limited partnership, limited liability
            company, or other legal entity, and any employees of such entities,
            who accesses, uses, or creates an online account in USCIS’
            electronic immigration system and information technology resource,
            which includes the network, application, managed access developer
            portal, external drives that upload, process, store, receive or
            transmit information.
          </p>
          <p className="terms-paragraph">
            USCIS’ electronic immigration system is an online, account-based
            system that allows users to submit requests for immigration benefits
            and certain immigration services, as well as view certain
            applications, petitions, and requests; receive electronic
            notification of USCIS notices and decisions; confirm employment
            eligibility and lock employment eligibility information against
            misuse; submit Freedom of Information Act (FOIA) and Privacy Act
            requests; receive real-time status updates on pending cases and work
            eligibility; and use defined roles to access certain technical
            specifications within designated interface portals. USCIS’
            electronic immigration system allows individuals, attorneys,
            accredited representatives, and other authorized users to
            conveniently and securely set up and manage online accounts;
            electronically submit certain applications, registrations,
            petitions, or requests and supporting documents; receive and respond
            to USCIS notices, requests for evidence, and decisions
            electronically; and pay filing fees, biometric services fees,
            registration fees, and immigrant fees. Periodically, USCIS will
            release additional functionality that will allow individuals,
            attorneys, and accredited representatives to perform additional
            functions online, which may include, paying service and processing
            fees, filing motions to reopen or reconsider, submitting program
            registrations, engaging in technical integration capabilities with
            programming interfaces, exposed data, and content management
            systems, paying administrative appeal fees, confirming employment
            eligibility, obtaining FOIA or Privacy Act responses, and requesting
            fee waivers online.
          </p>
          <p className="terms-paragraph">
            These <b>Terms</b> require that you use this system only to perform
            lawful immigration-related functions and to communicate with the
            Department of Homeland Security (DHS) and any component thereof,
            including USCIS. The <b>Terms</b> detail the laws, policies, and
            procedures you must abide by when using the system to submit any
            application, petition, registration, or request to USCIS, submit
            technical programming interface data, access exposed data content,
            or communicate with DHS and/or USCIS. The <b>Terms</b> also detail
            what actions are prohibited when using the system and the penalties
            for misuse.
          </p>
          <p className="terms-paragraph">
            By accessing or using this system, you are legally bound by the
            terms and conditions noted below. If you do not agree with any
            provision of these <b>Terms</b> then you may not access USCIS’
            online electronic immigration system for any purpose including, but
            not limited to, creating an account, filing an application,
            petition, registration, or request, paying a filing and/or biometric
            services fee, paying a registration fee, checking a status online,
            confirming employment eligibility, and requesting assistance with
            access, use or technical issues associated with the USCIS online
            electronic immigration system, engaging in technical integration
            with: programming interfaces, exposed data, and content management
            systems, or managed technical access to the developer portal. Any
            violation of these <b>Terms</b> may result in immediate suspension
            or termination of any access provided to the USCIS online electronic
            immigration system and may result in administrative or civil actions
            as allowed by law, or criminal prosecution.
          </p>
          <p className="terms-paragraph"></p>
        </div>
        <div>
          <ol className="terms-of-use-ol p-3">
            <h3 tabIndex={0} className="terms-subheading text-xl">
              <li>Governing Laws</li>
            </h3>
            <ul className="terms-of-use-bullet">
              <li>
                These <b>Terms</b> are governed and construed in accordance with
                the laws of the United States. Any failure by DHS or any
                component thereof, including USCIS, to enforce any provision in
                the <b>Terms</b> will not be considered a waiver of any
                privileges, immunities, or rights provided under applicable
                Federal, state, and foreign laws. These <b>Terms</b> do not
                create any right or benefit, substantive or procedural, for any
                individual or entity.
              </li>
              <li>
                If any provision of these <b>Terms</b> is held to be invalid or
                unenforceable by a court of law, the remaining provisions of
                these <b>Terms</b> shall be severable from the invalid or
                unenforceable provision and will remain in effect.
              </li>
              <li>
                To the extent permitted by law, USCIS disclaims all warranties
                whether express or implied, including the implied warranties of
                merchantability, fitness, for a particular purpose, title, and
                non-infringement. Accordingly, our liability shall be limited to
                the fullest extent permitted by applicable law, and under no
                circumstance will we be liable to participants or others for any
                lost profits, revenues, information, or data, or consequential,
                special, indirect, exemplary, punitive, or incidental damages
                arising out of or related to participation and use of the
                services and program accessed online using USCIS myAccount, or
                its successors.
              </li>
            </ul>

            <h3 tabIndex={0} className="terms-subheading text-xl">
              <li>Trademarks and Logos</li>
            </h3>
            <ul className="terms-of-use-bullet">
              <li>
                DHS is the trademark owner and authorized user of the myAccount
                name and logo and the name and logo of the various systems
                accessible from this portal, including, but not limited to,
                myUSCIS, FIRST, E-Verify and myE-Verify, 3PI, and all
                successors. Users may not use these trademarked items without
                the prior written consent of DHS.
              </li>
              <li>
                Nothing in these <b>Terms</b> shall be interpreted as
                authorization to use the official seal, insignia or other visual
                identities of DHS, USCIS, E-Verify, myE-Verify, FIRST, myUSCIS,
                USCIS ELIS, myAccount, 3PI, or any other DHS or USCIS service or
                system, (for example, myE-Verify Self Check). Participants shall
                not use the DHS, USCIS, FIRST, myUSCIS, USCIS ELIS, myAccount,
                E-Verify, myE-Verify or Self Check seal, insignia, or other
                visual identities in any manner to imply endorsement of any
                commercial product or activity by DHS, USCIS, E-Verify,
                myE-Verify or the United States government. Use of these seals,
                insignia or other visual identities without authorization
                violates federal law (See, 18 U.S.C. §§ 506, 701, 1017).
                Creation of an online account and use of these systems and
                services or the receipt of information from any USCIS portal
                does not grant any licenses to copyrights, patents, any other
                intellectual property rights, or the rights to any of the
                materials on the site or sites or materials accessed by use of
                the site. Users warrant that none of the information provided to
                participate in or use the USCIS online electronic immigration
                system (including an individual, unique e-mail address)
                infringes any copyright or the intellectual property rights of
                any third parties.
              </li>
            </ul>

            <h3 tabIndex={0} className="terms-subheading text-xl">
              <li>Rules of Behavior</li>
            </h3>
            <p>
              The Rules of Behavior (RoB) noted below are consistent with the
              purpose of USCIS’ online electronic immigration system.
            </p>
            <ul className="terms-of-use-bullet mt-2">
              <li>
                I understand that any access granted to me by DHS or any
                component thereof, including USCIS, for use of USCIS’ electronic
                immigration system is based on my need to perform specific
                online tasks and activities related to the managed technical
                access to the developer portal, configurations of programming
                interfaces, or other specifically permitted access, preparation
                and filing of requests for immigration benefits and services
                (such as, applications, petitions, registrations, and payment of
                various fees), relief from removal (such as, requests filed with
                USCIS by aliens in exclusion, deportation, or removal
                proceedings), obtaining or verifying legal status,
                administrative appeals, motions to reopen or reconsider any
                rendered decisions, legal status, confirmation of employment
                eligibility, FOIA or Privacy Act response or other associated
                relief , benefit, or service.
              </li>
            </ul>
            <h3 tabIndex={0} className="text-xl font-bold my-4">
              I agree to the following:
            </h3>
            <ul className="terms-of-use-ol-letter px-3">
              <h4 tabIndex={0} className="text-lg">
                <li>Use</li>
              </h4>
              <ol className="terms-of-use-ol-number p-4">
                <h5 tabIndex={0} className="mb-4 text-md terms-num-subheading">
                  <li>Online Account Holders</li>
                </h5>
                <ul className="terms-of-use-bullet">
                  <li>
                    I will only use USCIS’ electronic immigration system as
                    designed and to perform authorized U.S. immigration-related
                    functions and communicate with DHS and USCIS.
                  </li>
                  <li>
                    I will not create an online account unless I am at least 14
                    years of age. In the event I am under 14, a parent or legal
                    guardian must create an online account in their own name in
                    order to file applications, petitions, check the status of a
                    case or request submitted on my behalf, or submit other
                    appropriate requests or engage in other authorized use.
                  </li>
                  <li>
                    I will only use USCIS’ electronic immigration system for
                    official DHS or USCIS-related communications, notices,
                    responses, or correspondence in relation to any immigration
                    application, petition, registration, or request; request for
                    legal status or deferred action; relief from removal
                    proceedings; request for advance parole or employment
                    authorization; confirmation of employment eligibility;
                    request a FOIA or Privacy Act response; payment of filing
                    fees, biometric services fees, premium processing fees,
                    payment of registration fees, motions to reopen or
                    reconsider or administrative appeal fees, payment of
                    immigrant fees or for filing a request for a fee waiver;
                    access to the developer portal; or for authorized review and
                    configuration of technical programming interface products.
                  </li>
                  <li>
                    I will abide by all applicable laws and regulations that
                    pertain to the submission of an application, petition,
                    registration, or request to USCIS; request for legal status
                    or deferred action; relief from removal proceedings; request
                    for advance parole or employment authorization; confirmation
                    of employment eligibility; payment of filing fees, biometric
                    services fees, premium processing fees, registration fees,
                    request for FOIA or Privacy Act response motions to reopen
                    or reconsider or administrative appeal fees, and/or
                    immigrant fees or for filing requests for a fee waiver
                    online.
                  </li>
                  <li>
                    I will also submit any requests for immigration benefits
                    under penalty of perjury and with a certification that I
                    understand the contents of the filing and supporting
                    documents submitted with any filing.
                  </li>
                  <li>
                    I will abide by all applicable laws and regulations related
                    to the access and use of any DHS or USCIS government
                    computer system, including USCIS’ online electronic
                    immigration system, designated for the filing, adjudication,
                    communication on any application, petition, registration, or
                    request for an immigration benefit; request for legal status
                    or deferred action; request for advance parole or employment
                    authorization; confirmation of employment eligibility;
                    request for a FOIA or Privacy Act response; payment of
                    filing fees, biometric services fees, premium processing
                    fees, registration fees, other service fees, motions to
                    reopen or reconsider or administrative appeal fees, payment
                    of immigrant fees or for filing requests for a fee waiver
                    online; or for authorized review and configuration of
                    technical programming interface products.
                  </li>
                  <li>
                    I understand that DHS reserves the right to monitor usage
                    and activity on the USCIS online electronic immigration
                    system. DHS may view or scan any file stored on the
                    electronic immigration system. I understand that DHS
                    maintains a right to access the contents of any stored data
                    on the USCIS online electronic immigration system at any
                    time, for any purpose, including the enforcement of the
                    immigration laws and any laws related to the access and use
                    of government systems. All electronic activity on the USCIS
                    online electronic immigration system may be inspected and is
                    subject to monitoring without additional notice, as deemed
                    necessary, and to the full extent, unless expressly
                    prohibited by applicable statutes. I understand that the use
                    of USCIS’ online electronic immigration system constitutes
                    my consent to collection, monitoring and auditing of my use
                    of USCIS’ online electronic immigration system, by DHS and
                    any of its components and by any Federal, state or local law
                    enforcement entity, for implementation, adjudication,
                    investigation, and enforcement of the immigration and
                    criminal laws. This does not abrogate any limitations on
                    information disclosure specified under any system of records
                    notice governing access and use of DHS systems.
                  </li>
                  <li>
                    I understand that I must create, provide, and maintain the
                    security of my unique online account, account password,
                    authentication code, other log-in and account creation
                    features and requirements, as well as the answers to
                    security questions for password reset.
                  </li>
                  <li>
                    I understand that to access or use USCIS’ online electronic
                    immigration system, I must use valid credentials to access
                    an account I am authorized to access by DHS or USCIS. I
                    agree to use my online account for the purpose for which it
                    was created and in the manner it was designed. I agree to
                    use a valid personal and personally-controlled, unique email
                    address or username and other required authentication
                    credentials with each online access attempt and session. I
                    agree to use a valid unique, online account password and the
                    generated access passcode delivered by email or SMS (“Short
                    Message Service”), MMS (Multi-Media Message Service) text
                    message, or any other provided authentication credential
                    intended for access to and use of USCIS’ online electronic
                    immigration system. I agree that each individual user,
                    including legal representatives and authorized agents,
                    should have an individual, unique email address and online
                    account password for their account. Each online account is
                    used to certify that any information submitted in the online
                    electronic immigration system is true and correct, and that
                    the electronic signature shall be considered an affirmation,
                    under penalty of perjury, that any such information
                    submitted, was submitted with full knowledge, intent, and
                    understanding, or, if represented, was expressly agreed to
                    and authorized by my legal representative or the company’s
                    legal representative, in the event of registration or
                    certain select services and processes.
                  </li>
                  <li>
                    I agree to use my personal online account solely for filing
                    a request for myself or, in the case of attorneys and
                    accredited representatives, on behalf of an individual
                    client or corporate entity, as applicable. In the case of
                    account holders who are authorized and registered to
                    participate in the developer community of users, I agree to
                    use my personal online account solely to access the managed
                    developer portal and in compliance with my assigned user
                    role and permissions. When using a registration account, or
                    account type such as an applicant, petitioner, requestor, or
                    legal representative account, I agree to use the account I
                    created solely to submit required registrations or other
                    required submissions that I am authorized to submit to USCIS
                    on behalf of an employer, agent, or other authorized
                    individual or entity. In the instance of submitting requests
                    on behalf of minor children, incapacitated or disabled
                    individuals, if requested by DHS or USCIS, I agree to
                    provide proof of authority such as guardianship orders,
                    proof of recognized family relationship, or other required
                    information and evidence.
                  </li>
                  <li>
                    I am responsible for safeguarding my unique credentials
                    associated with my online account, including my unique email
                    address associated with my online account, username, account
                    password, and passcode and not disclosing it to any other
                    person. I understand that, as a USCIS’ online electronic
                    immigration system account holder and user, I am the only
                    person authorized to use my access credentials to access my
                    assigned online account in USCIS’ online electronic
                    immigration system.
                  </li>
                  <li>
                    If my online account has been compromised in any way. I will
                    notify USCIS immediately, by either calling the Contact
                    Center at 800-375-5283 or sending USCIS an electronic
                    message as instructed on the uscis.gov website.
                  </li>
                  <li>
                    My identity will be tied to my access credentials and the
                    USCIS online electronic immigration system account to which
                    I have requested and been granted access. I understand that
                    my ability to electronically sign and submit applications,
                    petitions, registrations, or various requests, including
                    submissions of technical configurations, is directly tied to
                    my USCIS online electronic immigration system account. I
                    understand that my legal representative, if any, will be
                    able to submit an application, petition, or request on my
                    behalf after I have expressly agreed to his or her
                    representation and authorized him or her to file requests
                    with DHS or any of its components. If submitting
                    registrations or other authorized submissions as an
                    authorized official for a company or other organization or
                    entity using a registration account or other specialized
                    account, I agree that I can only do so if I have obtained
                    express authorization from the company, organization, or
                    entity to submit registrations or other authorized
                    submissions.
                  </li>
                </ul>

                <h5 tabIndex={0} className="my-4 text-md terms-num-subheading">
                  <li>
                    Attorney and Accredited Representative Online Account
                    Holders
                  </li>
                </h5>
                <p>
                  If I am an attorney or accredited representative seeking to
                  represent, representing, or submitting an application,
                  petition, registration, or request to USCIS on behalf of an
                  applicant, petitioner, beneficiary, or requester seeking an
                  immigration benefit, immigration service, legal status or
                  deferred action; relief from removal proceedings; advance
                  parole or employment authorization; confirmation of employment
                  eligibility; payment of filing fees, biometric services fees,
                  payment of premium processing fees, payment of registration
                  fee, submitting motions to reopen or reconsider or
                  administrative appeal fees, and/or immigrant fees or for
                  filing requests for a fee waiver, or any other tasks or action
                  on the USCIS online electronic immigration system, I will:
                </p>
                <ul className="terms-of-use-bullet mt-4">
                  <li>
                    Abide by the rules of professional conduct prescribed under
                    section 292 of the Immigration and Nationality Act (8 U.S.C.
                    1362) and Title 8 of the Code of Federal Regulations, parts
                    292 and 1003.
                  </li>
                  <li>
                    Timely submit a signed Notice of Entry of Appearance as
                    Attorney or Accredited Representative, Form G-28, in the
                    manner and format, designated by USCIS, to notify USCIS of
                    my appearance as an Attorney or Accredited Representative on
                    behalf of the applicant, petitioner, beneficiary, registrant
                    or requester.
                  </li>
                  <li>
                    Ensure that when representing an individual person, each
                    client I represent has created his or her own online
                    account, with unique credentials pertinent to the
                    established account, such as an email address, username, and
                    online account password and that each client I represent can
                    access, review and personally electronically sign his or her
                    application, petition, or request I have prepared on his or
                    her behalf in USCIS’ online electronic immigration system,
                    through their own personal unique account.
                  </li>
                  <li>
                    Only create, access, and use the representative online
                    account, authorized by DHS and USCIS, for interaction with
                    USCIS’ online electronic immigration system, to prepare an
                    application, petition, request, registration, or submit fee
                    payments or registration payments on behalf of my client and
                    to electronically sign the designated form notifying USCIS
                    of my appearance as my client’s online attorney or
                    accredited representative.
                  </li>
                  <li>
                    Be responsible for the proper protection and disclosure of
                    any personally identifiable information (PII) to USCIS via
                    USCIS’ online electronic immigration system. Any potential
                    improper disclosure of PII that occurs as the result of my
                    intended or inadvertent misuse of USCIS’s online electronic
                    immigration system is my responsibility to disclose and
                    remedy. At its discretion, USCIS may notify me and/or my
                    clients in the event of a suspected improper disclosure of
                    PII.
                  </li>
                </ul>
              </ol>
              <h4 tabIndex={0} className="text-xl">
                <li>Prohibitions</li>
              </h4>
              <ol className="terms-of-use-ol-number p-4">
                <h5 tabIndex={0} className="mb-4 text-md terms-num-subheading">
                  <li>Online Account Holders</li>
                </h5>
                <p>
                  If I am an individual seeking to file an application,
                  petition, registration, request, or appeal through USCIS’
                  online electronic immigration system for any immigration
                  benefit, immigration service, legal status or deferred action;
                  relief from removal proceedings; advance parole or employment
                  authorization; confirmation of employment eligibility; receipt
                  of FOIA or Privacy Act response; payment of filing fees,
                  biometric services fees, premium processing fees, registration
                  fees, immigrant fees, motion or appeal filing fees, filing
                  requests for a fee waiver online, any other service fee or
                  benefit request, or if I am engaging in authorized review and
                  configuration of technical programming interface products,
                  managed technical access to the developer portal, content
                  management systems, or other specifically permitted access, I
                  agree that I will not:
                </p>
                <ul className="terms-of-use-bullet mt-3">
                  <li>
                    Access any user accounts, data stores, systems,
                    applications, form types, backend data, or technical
                    programming interfaces to which I have not been granted
                    access and that are not expressly authorized for access to
                    or use by me by DHS or USCIS, or create any online account
                    if I am under the age of 14.
                  </li>
                  <li>
                    Attempt to bypass access control, monitoring, or auditing
                    measures instituted by DHS or USCIS to ensure the integrity
                    and security of U.S. government systems, including USCIS
                    online electronic immigration system.
                  </li>
                  <li>
                    Use anyone else’s online account, secure account password,
                    authentication credentials or answers to security questions
                    for password reset to access or use USCIS’s online
                    electronic immigration system.
                  </li>
                  <li>
                    Share my email address, username, secure account password,
                    online access code, or any other user identification or
                    authentication credential used to access USCIS’s online
                    electronic immigration system with anyone else for their own
                    use in the USCIS online electronic immigration system.
                  </li>
                  <li>
                    Authorize another person to log into, access, or use my
                    USCIS online electronic immigration system account.
                  </li>
                  <li>
                    Allow or permit another person or legal entity to
                    electronically sign any application, petition, registration,
                    or request for me and that I am required to personally,
                    electronically sign any application, petition, registration,
                    or request that I am submitting unless I am mentally
                    incompetent, have a legally designated guardian or
                    surrogate, or am a minor child under 14 years of age.
                  </li>
                  <li>
                    Allow another person, attorney, accredited representative,
                    company, legal entity, organization, or authorized agent
                    acting on behalf of a company, legal entity, corporation,
                    partnership, or other organized business, to setup or manage
                    my online individual account, secure account password,
                    authentication credentials or answers to security questions
                    for password reset to access USCIS’ online electronic
                    immigration system on my behalf as an individual applicant,
                    petitioner, registrant, requestor or technical contributor.
                    Exceptions apply to a legal guardian, parent (if requestor
                    is of minor age), or other agent expressly authorized and
                    permitted to act on behalf of another individual by DHS or
                    USCIS.
                  </li>
                  <li>
                    Use my USCIS online electronic immigration system account or
                    individual authentication credentials to file or
                    electronically sign an application, petition, registration,
                    or request for someone other than myself, confirm employment
                    eligibility, submit or access technical programming
                    interface data without permission, or file or electronically
                    sign an application, petition, or request for more than one
                    person, unless I am acting as that person’s legal guardian,
                    parent, or acting pursuant to a lawful grant of power of
                    attorney on that person’s behalf as recognized and
                    authorized by DHS or USCIS.
                  </li>
                </ul>

                <h5 tabIndex={0} className="my-4 text-md terms-num-subheading">
                  <li>
                    Attorneys and Accredited Representatives Online Account
                    Holders
                  </li>
                </h5>
                <p>
                  If I am an attorney or accredited representative seeking to
                  represent, representing, or submitting an application,
                  petition, registration, request, or appeal on behalf of an
                  applicant, petitioner, beneficiary, or requester seeking an
                  immigration benefit, legal status or deferred action; relief
                  from removal proceedings; advance parole or employment
                  authorization; confirmation of employment eligibility; receipt
                  of FOIA or Privacy Act response; payment of filing fees,
                  biometric services fees, premium processing fees, registration
                  fees, immigrant fees, motion or appeal fees, filing requests
                  for a fee waiver online, or any other service fee or benefit
                  request I agree that:
                </p>
                <ul className="terms-of-use-bullet mt-3">
                  <li>
                    I cannot act or appear on behalf of an applicant,
                    petitioner, requester, employer, or agent until after he or
                    she has consented to my representation pursuant to 8 CFR
                    292.4 and has personally executed his or her signature on
                    all required documentation.
                  </li>
                  <li>
                    I will not file an application, petition, request,
                    registration, or other submission in USCIS’ online
                    electronic immigration system until I notify USCIS of my
                    appearance as an attorney or accredited representative on
                    behalf of the applicant, petitioner, or requester I seek to
                    represent, and this notice is appropriately submitted to DHS
                    or USCIS in accordance with laws and regulations.
                  </li>
                  <li>
                    I will not electronically sign an application, petition,
                    request, or registration for an applicant, petitioner,
                    registrant or requester in USCIS’ online electronic
                    immigration system. I acknowledge that it is the duty of my
                    client to sign the application, petition, registration, or
                    request under penalty of perjury, certifying that he or she
                    understands the contents of the filing and supporting
                    documents submitted with any filing.
                  </li>
                  <li>
                    I will not allow different applicants, petitioners,
                    registrants, or requesters to use or share the same online
                    account or to be linked or granted access to a USCIS’ online
                    electronic immigration system account that is not theirs and
                    associated to their individual unique authentication
                    credentials.
                  </li>
                  <li>
                    I will only use the authorized representative online account
                    that I created to access and use USCIS’ online electronic
                    immigration system or obtain any information contained
                    therein.
                  </li>
                  <li>
                    I will not access USCIS’s online electronic immigration
                    system using an applicant’s, petitioner’s, registrant's, or
                    requester’s online account or perform any actions under
                    cover of applicant’s, petitioner’s, registrant's, or
                    requester’s online account, including executing any required
                    electronic signatures.
                  </li>
                </ul>

                <h5 tabIndex={0} className="my-4 terms-num-subheading">
                  <li>
                    Legal Entities, Corporations, Partnerships, Companies,
                    Organized Business, and Agents Acting on Behalf of Such
                    Entities
                  </li>
                </h5>
                <p>
                  If I am an authorized official or agent acting on behalf of a
                  company, legal entity, corporation, partnership, or other
                  organized business, including, but not limited to the
                  preparation of applications, petitions, requests,
                  registrations, or other submissions, or providing
                  interpretation or translation services for the filing or
                  submission of an application, petition, request, registration,
                  or other submission, including submissions of technical
                  configurations, and programming interface data with the DHS or
                  any component thereof, including USCIS, U.S. Customs and
                  Border Protection, and U.S. Immigration and Customs
                  Enforcement, I agree that:
                </p>
                <ul className="terms-of-use-bullet mt-3">
                  <li>
                    I may not file any application, petition, request,
                    registration, or other submission with DHS or any component
                    thereof, including USCIS, without the express consent of the
                    applicant, petitioner, registrant, or requester.
                  </li>
                  <li>
                    I must abide by all Federal, state, and local laws governing
                    the authorized practice of law and prohibiting the
                    unauthorized practice of law in the jurisdiction in which
                    the company, legal entity, corporation, partnership, or
                    other organized business or authorized agent acting on
                    behalf of a company, legal entity, corporation, partnership,
                    or other organized business, resides, has a physical
                    presence, conducts business, or authorizes transactions
                    involving any interactions with USCIS, DHS, or any component
                    thereof.
                  </li>
                </ul>
              </ol>
              <h4 tabIndex={0} className="text-xl my-4">
                <li>
                  Protect Personally Identifiable Information (PII) –
                  (Applicable to All Users)
                </li>
              </h4>
              <ul className="terms-of-use-bullet">
                <li>
                  I understand, for the purpose of accessing USCIS’ online
                  electronic immigration system, that I may be handling or
                  uploading documents or data that contain PII, and I affirm
                  that I will adhere to all privacy laws and rules governing the
                  safeguarding and use of PII.
                </li>
                <li>
                  I agree to safeguard PII information from accidental,
                  unintentional, or unauthorized disclosure at any time and to
                  any person not authorized to have access to such information.
                </li>
              </ul>

              <h4 tabIndex={0} className="text-xl my-4">
                <li>Incident Reporting – (Applicable to All Users)</li>
              </h4>
              <ul className="terms-of-use-bullet">
                <li>
                  I will cooperate willingly with DHS or USCIS on any
                  investigation of any privacy, security, or cyber incidents
                  and, if directed by DHS or USCIS, I will voluntarily
                  relinquish all access to and use of USCIS online electronic
                  immigration system during the period of investigation and/or
                  law enforcement action in response to such incidents.
                </li>
                <li>
                  If my online account has been compromised in any way. I will
                  notify USCIS immediately, by either calling the Contact Center
                  at 800-375-5283 or sending USCIS an electronic message as
                  instructed on the uscis.gov website.
                </li>
              </ul>

              <h4 tabIndex={0} className="text-xl my-4">
                <li>Penalties for Misuse – (Applicable to All Users)</li>
              </h4>
              <ul className="terms-of-use-bullet">
                <li>
                  I understand that any violation of these Terms may result in
                  immediate suspension or termination of my access to USCIS
                  online electronic immigration system and may subject me to
                  administrative or civil actions as allowed by law, or
                  criminal.
                </li>
                <li>
                  I agree that I may be held accountable for my actions or
                  inactions that result in any DHS or USCIS computer security
                  incident, privacy incident, or computer security violation.
                  This includes the possibility of immediate suspension or
                  termination of my access to USCIS online electronic
                  immigration system, administrative or civil actions as allowed
                  by law, or criminal prosecution.
                </li>
              </ul>
            </ul>

            <h3 tabIndex={0} className="terms-subheading text-2xl">
              <li>
                Termination of Access or Use of USCIS’ Online electronic
                immigration System
              </li>
            </h3>
            <ul className="terms-of-use-bullet">
              <li>
                USCIS, in its sole discretion, may suspend or terminate account
                access to and use of USCIS online electronic immigration system
                immediately, without prior notice.
              </li>
              <li>
                USCIS also will suspend or terminate service if USCIS determines
                there has been a privacy or security breach, misuse of the
                system, or breach in the <b>Terms</b> by any user.
              </li>
              <li>
                USCIS reserves the right to reinstate or restore service to any
                specific user after USCIS has made a determination, in its
                discretion, that restoration or reinstatement of service is
                warranted.
              </li>
            </ul>

            <h3 tabIndex={0} className="terms-subheading text-2xl">
              <li>Links to Other Web Sites</li>
            </h3>
            <ul className="terms-of-use-bullet">
              <li>
                USCIS’ online electronic immigration system may contain links to
                third party web sites or services that are not owned or
                controlled by DHS or USCIS. These may include federal, state,
                and local government websites of the United States, and may also
                include websites owned and operated by private entities.
              </li>
              <li>
                USCIS has no control over, and assumes no responsibility for the
                content, privacy policies, or practices of any third party web
                sites or services. I acknowledge and agree that USCIS shall not
                be responsible or liable, directly or indirectly, for any damage
                or loss caused or alleged to be caused by or in connection with
                use of or reliance on any such content, goods or services
                available on or through any such web sites or services. DHS and
                USCIS strongly advise individuals who intend on accessing USCIS’
                online electronic immigration system and third party websites to
                read the terms and conditions and privacy policies of any third
                party web sites or services.
              </li>
            </ul>

            <h3 tabIndex={0} className="terms-subheading text-2xl">
              <li>Changes to Terms of Use</li>
            </h3>
            <ul className="terms-of-use-bullet">
              <li>
                USCIS reserves the right, at its sole discretion, to modify or
                replace these <b>Terms</b> at any time.
              </li>
              <li>
                By continuing to access or use USCIS’ online electronic
                immigration system after updated revisions become effective, I
                agree to be bound by the revised <b>Terms</b>.
              </li>
              <li>
                If I do not agree to the new <b>Terms</b>, I agree not to access
                USCIS’ online electronic immigration system for any purpose
                including, but not limited to, creation of an online account,
                filing of an application, petition, registration, or request,
                paying a filing and/or biometric services fee, checking a case
                status online, requesting assistance with access, submitting
                technical configurations or programming interfaces, accessing
                specific end user data, or any technical issues associated with
                USCIS online electronic immigration system.
              </li>
            </ul>

            <h3
              id="terms-page-bottom"
              tabIndex={0}
              className="terms-subheading text-2xl"
            >
              <li>Acknowledgment</li>
            </h3>
            <p>
              By clicking "I agree", I acknowledge that I have read the{' '}
              <b>Terms</b> and understand and will comply with them. I agree to
              abide by the <b>Terms</b> and understand that if I do not accept
              the <b>Terms</b>, I am not eligible to access or use USCIS online
              electronic immigration system. I understand that any violations of
              these <b>Terms</b> and any criminal activity will result in
              immediate suspension and/or termination of my access to and use of
              USCIS online electronic immigration system and that I may be
              subject to administrative or civil action as allowed by law, or
              criminal prosecution.
            </p>
          </ol>
        </div>
      </div>

      <div className="button-container">
        <Button
          id="terms-submit-btn"
          data-testid="test-terms-submit-btn"
          text="I Agree"
          type="primary"
          className="mt-4"
          ref={agreeButtonRef}
          onClick={handleSubmit}
          disabled={submitButtonDisabled}
        />
      </div>
    </div>
  )
}

export default CreateAccountTos