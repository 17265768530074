import React, { forwardRef, useState } from 'react'

import AccordionItem from './AccordionItem'

interface AccordionProps {
  items: Array<{
    title: string
    body: any
  }>
}

const Accordion = (props: AccordionProps, ref: any) => {
  const { items } = props

  const [open, setOpen] = useState(-1)

  const handleClick = (idx: number) => {
    idx === open ? setOpen(-1) : setOpen(idx)
  }

  return (
    <div className="py-8" data-testid="accordion-container">
      {items.map((item, idx) => (
        <AccordionItem
          key={idx}
          id={idx}
          title={item.title}
          body={item.body}
          onClick={() => handleClick(idx)}
          open={open === idx}
        />
      ))}
    </div>
  )
}

export default forwardRef(Accordion)
