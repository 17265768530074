export interface ConfigurationProps {
  appSettings: Array<string>
  mobileView: boolean
  loginDotGovEmail: string
  loginDotGovUuid: string
  lg_linked: number
  lg_account_status: string
  accountTypeNeeded: boolean
}

export interface ConfigurationStateProps {
  configuration: ConfigurationProps
  setConfiguration: Function
}

export const defaultConfiguration = {
  configuration: {
    appSettings: [],
    mobileView: false,
    loginDotGovEmail: '',
    loginDotGovUuid: '',
    lg_linked: 0,
    lg_account_status: '',
    accountTypeNeeded: false,
  },
  setConfiguration: () => {},
}

export const testConfigurationData = {
  configuration: {
    appSettings: ['LoginGovModEnabled'],
    mobileView: false,
    loginDotGovEmail: 'test_email_com',
    loginDotGovUuid: '1111-2222-3333',
    lg_linked: 0,
    lg_account_status: '',
    accountTypeNeeded: false,
  },
  setConfiguration: () => {},
}

export const initialConfigurationState: ConfigurationStateProps = {
  ...defaultConfiguration,
}

export const testConfigurationInitialState: ConfigurationStateProps = {
  ...testConfigurationData,
}
