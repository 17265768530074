import React, {
  forwardRef,
  KeyboardEventHandler,
  MouseEventHandler,
} from 'react'
import './button.css'

/**
 *     Must use onMouseDown on the submit button because of the onBlur field
 *     validations. onClick causes user to have to click button twice
 */

interface ButtonProps {
  id: string
  text?: string | ''
  type?: string
  children?: any
  className?: string | ''
  disabled?: boolean | false
  ariaDisabled?: boolean | false
  ariaLabel?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  onMouseDown?: MouseEventHandler<HTMLButtonElement>
  onKeyDown?: KeyboardEventHandler<HTMLButtonElement>
  onEnter?: Function
  activationValidator?: Function
  image?: any
}

const ButtonContent = (props: { text: string; image: any }) => {
  const { text, image } = props
  return image ? (
    <>
      <div>{text}</div>
      <img className="px-1 pt-1" src={image} alt="login gov logo" />
    </>
  ) : (
    <div>{text}</div>
  )
}

const doNothing = () => { }

const Button = (props: ButtonProps, ref: any) => {
  const {
    id,
    text,
    type = 'primary',
    className,
    disabled = false,
    ariaDisabled = false,
    ariaLabel,
    onClick,
    onMouseDown,
    onKeyDown,
    onEnter,
    activationValidator,
    image,
    children
  } = props

  const activateOnClick = () => {
    let activationValidated = true
    if (activationValidator) {
      activationValidated = activationValidator()
    }

    return activationValidated && !disabled ? onClick : doNothing
  }
  const activateOnMouseDown = () => {
    let activationValidated = true
    if (activationValidator) {
      activationValidated = activationValidator()
    }

    return activationValidated && !disabled ? onMouseDown : doNothing
  }
  const activateOnKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (onEnter && e.key === 'Enter') {
      let activationValidated = true
      if (activationValidator) {
        activationValidated = activationValidator()
      }

      if (activationValidated) {
        e.preventDefault()
        onEnter()
      }
    }

    return onKeyDown ? onKeyDown(e) : null
  }

  return (
    <button
      id={id}
      data-testid={id}
      aria-label={ariaLabel || text}
      className={`${type} ${className} ${ariaDisabled || disabled ? 'bg-dhs_gray_20 text-black' : ''
        }`}
      onClick={activateOnClick()}
      onMouseDown={activateOnMouseDown()}
      onKeyDown={activateOnKeyDown}
      aria-disabled={ariaDisabled || disabled}
      ref={ref}
    >
      {text ? <ButtonContent text={text} image={image} /> : children}
    </button>
  )
}

export default forwardRef(Button)
