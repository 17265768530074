import React from 'react'
export const SectionalAlertInfoIcon = () => {
    return (
        <svg id="Alert_Icon_-_Info" data-name="Alert Icon - Info" xmlns="http://www.w3.org/2000/svg" className='flex-shrink-0 overflow-visible' viewBox="0 0 20 20">
            <title>Info Icon</title>
            <path id="info-circle" d="M18,8A10,10,0,1,0,28,18,10,10,0,0,0,18,8Zm0,4.435a1.694,1.694,0,1,1-1.694,1.694A1.694,1.694,0,0,1,18,12.435Zm2.258,10.242a.484.484,0,0,1-.484.484H16.226a.484.484,0,0,1-.484-.484V21.71a.484.484,0,0,1,.484-.484h.484V18.645h-.484a.484.484,0,0,1-.484-.484v-.968a.484.484,0,0,1,.484-.484h2.581a.484.484,0,0,1,.484.484v4.032h.484a.484.484,0,0,1,.484.484Z" transform="translate(-8 -8)" fill="#036" />
        </svg>

    )
}
