export const configurationActions = {
  SET_CONFIGURATION: "SET_CONFIGURATION",
}

export function ConfigurationReducer(state: any, action: any) {

  switch (action.type) {
    case configurationActions.SET_CONFIGURATION:
      return { ...state, configuration: action.value }
    default:
      return { ...state }
  }
}
