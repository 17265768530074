import React, { FunctionComponent } from "react";
import { FooterTop } from '../FooterTop';
import { FooterMid } from '../FooterMid';
import { FooterBottom } from '../FooterBottom';
import { ReturnToTop } from '../ReturnToTop';

export const Footer: FunctionComponent = () => {
  return (
    <div data-testid="footer-component">
      <div className="container mx-auto">
        <ReturnToTop />
      </div>
      <FooterTop />
      <FooterMid />
      <FooterBottom />
    </div>
  )
};

export default Footer;



