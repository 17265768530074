export const EMAIL_NO_ACCENTS_ERROR_MESSAGE =
  'Please enter a valid email address. Please do not include accented letters or letters outside of the Latin alphabet.'
export const EMAIL_ERROR_MESSAGE = 'Please enter a valid email address'

export function validateEmailFormat(
  input: String,
  allowAccents: boolean = false
) {
  if (input === '' && allowAccents) return EMAIL_ERROR_MESSAGE
  if (input === '' && !allowAccents) return EMAIL_NO_ACCENTS_ERROR_MESSAGE
  var validEmailRegex_no_accented_characters =
    /^[0-9A-Za-z.+\u2018\u2019\u0027_-]+@([0-9A-Za-z._-])+\.[A-Za-z]*$/
  var validEmailRegex_allow_accented_characters =
    /^[0-9A-Za-z\u00C0-\u1FFF\u2018-\u2019\u2800-\uFFFD.+\'_-]+@([0-9A-Za-z\u00C0-\u1FFF\u2800-\uFFFD._-])+\.[A-Za-z\u00C0-\u1FFF\u2800-\uFFFD]*$/
  let validateEmailMessage = ''
  if (allowAccents) {
    validateEmailMessage = !!input.match(
      validEmailRegex_allow_accented_characters
    )
      ? ''
      : EMAIL_ERROR_MESSAGE
  } else {
    validateEmailMessage = !!input.match(validEmailRegex_no_accented_characters)
      ? ''
      : EMAIL_NO_ACCENTS_ERROR_MESSAGE
  }
  return validateEmailMessage
}

export function formatPhoneNumber(value: any) {
  if (!value) return ''
  return value.replace('+1 ', '')
}

export function validateMobileFormat(value: any) {
  if (value === '' || value === undefined) {
    return 'Mobile number cannot be blank'
  } else if (value[0] === '0' || value[0] === '1' || value.length !== 10) {
    return 'Mobile number is invalid. Please make sure you enter a 10-digit phone number.'
  }
  return ''
}

export const timestampToDateTime = (timeIn: string | '') => {
  let formatTimeIn = new Date(timeIn)
  if (timeIn === '' || timeIn === null) return ''
  const dateStr = `${formatTimeIn.toLocaleString('default', {
    dateStyle: 'long',
  })} ${formatTimeIn.toLocaleString('default', { timeStyle: 'long' })}`

  return dateStr
}

export const exceededMaximumAttempts = (
  <div>
    Sorry, you have exceeded the maximum number of attempts to reset your
    password. Please{' '}
    <a
      className="nav-link inline-flex items-center px-0"
      target="_blank"
      rel="noreferrer"
      href="https://www.uscis.gov/about-us/contact-us"
    >
      Contact Us
    </a>{' '}
    for assistance.
  </div>
)

export const throttleAlert = {
  type: 'info',
  message: 'We have noticed multiple attempts to either create an online account or submit a request using this email address. Please check your inbox, spam or junk folder before making another attempt.',
  shouldPersist: true
}