import React, { useState } from "react"
// import { BlackLockIcon } from "../../../assets/icons/blackLock";
import flagSrc from "../../../assets/images/header/icon-us-flag-46x26.png"
import govSrc from "../../../assets/images/header/icon-dot-gov-80x80.png"
import lockSrc from "../../../assets/images/header/icon-https-80x80.png"
import blackLock from "../../../assets/images/header/icon-black-lock.svg"

export const GovBanner = () => {

  const [isExpanded, setExpanded] = useState(false)
  //migrating svg's to seperate files prevented inheritance of text styling
  const downArrow = <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
    <title>drop down expand</title>
    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
  </svg>

  const upArrow = <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
    <title>drop down collapse</title>
    <path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clipRule="evenodd" />
  </svg>

  const toggleExpand = (evt: React.MouseEvent<HTMLElement>) => {
    evt.preventDefault()
    setExpanded(!isExpanded)
  }

  return (
    <div className="max-w-screen-lg lg:mx-auto">
      <div className="flex flex-col sm:flex-row sm:items-center sm:pb-2 sm:relative ">
        <div className="flex flex-row items-center ml-4 mt-2">
          <img
            src={flagSrc}
            alt="An official website of the United States government"
            className=" h-3.5 w-6 mr-0.5"
          />
          <span className="mx-2 text-tiniest text-dhs-hyperlink-02 tracking-tighter">
            An official website of the United States government
          </span>
        </div>
        <button
          data-testid="howyouknow-button"
          className="inline-flex ml-12 sm:ml-0 sm:mt-2 cursor-pointer text-tiny text-dhs-blue-01 hover:text-dhs-hyperlink-02 underline underline-offset-1 focus:ring-2 focus:ring-offset-2 focus:ring-dhs-blue-02 outline-none"
          aria-expanded={isExpanded}
          onClick={(evt) => toggleExpand(evt)}
        >
          Here's how you know
          {isExpanded ? <span> {upArrow} </span> : <span> {downArrow} </span>}
        </button>
      </div>
      <div aria-live="polite">
        {isExpanded ? (
          <div
            data-testid="expanded-view-banner"
            className="flex flex-col sm:flex-row sm:justify-center sm:space-x-1 mx-4 my-4 pb-2 max-w-screen-lg lg:mx-auto lg:px-4"
          >
            <div className="flex flex-row mb-3 sm:w-1/2">
              <img className="h-10 w-10" src={govSrc} alt="Dot gov" />
              <div className="flex flex-col text-tiny ml-2">
                <strong className="pb-1">Official websites use .gov </strong>
                <p>
                  A <strong>.gov</strong> website belongs to an official
                  government organization in the United States.
                </p>
              </div>
            </div>
            <div className="flex flex-row  sm:w-1/2">
              <img src={lockSrc} alt="Secure" className="h-10 w-10" />
              <div className="flex flex-col text-tiny ml-2">
                <strong className="pb-1">
                  Secure .gov websites use HTTPS{' '}
                </strong>
                <p className="leading-6">
                  A{' '}
                  <strong>
                    lock ({' '}
                    <img
                      className="inline-block pb-1"
                      src={blackLock}
                      alt="a small black lock"
                    />{' '}
                    )
                  </strong>{' '}
                  or <strong>https://</strong> means you’ve safely connected to
                  the .gov website. Share sensitive information only on
                  official, secure websites.
                </p>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

GovBanner.displayName = "GovBanner"