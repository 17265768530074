import React from 'react'

export const CollapseIcon = (props: { color?: string; height?: string }) => {
  const { color, height = '24' } = props

  return (
    <svg
      data-testid="expand-small-icon"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height={height}
      viewBox="0 0 24 24"
    >
      <g
        id="System_Icon_-_Expand_small_"
        data-name="System Icon - Expand (small)"
        transform="translate(17 10.127) rotate(180)"
      >
        <path
          id="angle-down"
          d="M8.024,170.614l-7.861-7.8a.638.638,0,0,1,0-.9l1.052-1.052a.638.638,0,0,1,.9,0l6.358,6.294,6.358-6.294a.638.638,0,0,1,.9,0l1.052,1.052a.638.638,0,0,1,0,.9l-7.861,7.8A.638.638,0,0,1,8.024,170.614Z"
          transform="translate(0.025 -169)"
        />
      </g>
    </svg>
  )
}
