import React, { ChangeEventHandler, forwardRef, useState } from "react";
import './checkbox.css'
interface CheckboxProps {
  label: string | '',
  id?: string,
  checked?: boolean,
  className?: string | '';
  ariaLabel?: string;
  size?: string;
  onChange: ChangeEventHandler<HTMLInputElement>
}

const Checkbox = (props: CheckboxProps, ref: any) => {
  const { label, checked = false, className = '', onChange, id = 'checkbox', ariaLabel = '', size = '20px'} = props
  const [isChecked, setIsChecked] = useState(checked)
  const [isFocused, setIsFocused] = useState(false)
  const [isHovering, setIsHovering] = useState(false)

  const handleClick = (e: any) => {
    const toggle = !isChecked;
    setIsChecked(toggle);
    onChange(e)
  }

  return (
    <div className={`flex items-center ${className}`} >
      {/* This input is used for all functions of the checkbox */}
      <input
        data-testid="dhs-checkbox"
        id={id}
        className={'checkbox-input'}
        ref={ref}
        type="checkbox"
        checked={isChecked}
        onChange={handleClick}
        onFocus={() => { setIsFocused(true) }}
        onBlur={() => { setIsFocused(false) }}
        onMouseEnter={() => { setIsHovering(true) }}
        onMouseLeave={() => { setIsHovering(false) }}
        aria-label={ariaLabel !== '' ? ariaLabel : label}
      />
      {/* This checkbox is the corresponding visual representation  */}
      <div className={`custom-checkbox ${isFocused ? 'has-focus': ''} ${isHovering ? 'hovering': ''} ${isChecked ? 'checked' : 'unchecked'}`} style={{height: size, width: size}}>
        { isChecked &&
          <div className={`checkmark }`}>
            <div className="checkmark-body"></div>
            <div className="checkmark-foot"></div>
          </div>
        }
      </div>
      <label className="pl-2" htmlFor={id}>{label}</label>

    </div>
  )
}

export default forwardRef(Checkbox)
