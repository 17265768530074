import React, { useContext, useEffect, useRef, useState } from 'react'
import { useApiClient } from '../../../../hooks/useApiClient'
import { myAccountContext, pathContext } from '../../../../contexts/contexts'
import {
  validateEmailFormat,
  EMAIL_NO_ACCENTS_ERROR_MESSAGE,
} from '../../../../utils/utils'
import { Button, Checkbox, ValidatedInput } from '../../../baseComponents'
import PageTitleHook from '../../../../utils/PageTitleHook/PageTitleHook'

const ChangePrimaryEmail = () => {
  PageTitleHook('USCIS Online Account | Change your email')
  const { user, setAlert } = useContext(myAccountContext)
  const { setUrl } = useContext(pathContext)

  const [emailInputVal, setEmailInputVal] = useState('')
  const [emailInputValidationMsg, setEmailInputValidationMsg] = useState('')
  const [disabledSubmit, setDisabledSubmit] = useState(false)
  const [submitSignin, setSubmitSignin] = useState(false)

  const emailInputRef = useRef<HTMLInputElement>(null)

  const [passwordInputVal, setpasswordInputVal] = useState('')
  const [passwordInputValidationMsg, setPasswordInputValidationMsg] =
    useState('')
  const [pwdFieldType, setPwdFieldType] = useState('password')

  const passwordInputRef = useRef<HTMLInputElement>(null)
  const showPasswordRef = useRef<HTMLInputElement>(null)

  const apiClient = useApiClient()

  useEffect(() => {
    setEmailInputVal(user.email)
  }, [user.email])

  const handlePasswordCheckbox = (e: any) => {
    if (e.currentTarget.checked) setPwdFieldType('text')
    else setPwdFieldType('password')
  }

  const validateEmailField = () => {
    if (emailInputVal === '') {
      setEmailInputValidationMsg(EMAIL_NO_ACCENTS_ERROR_MESSAGE)
    } else {
      setEmailInputValidationMsg(validateEmailFormat(emailInputVal.trim()))
    }
  }

  const validatePasswordField = () => {
    passwordInputVal === ''
      ? setPasswordInputValidationMsg('Password cannot be blank')
      : setPasswordInputValidationMsg('')
  }

  const handleSubmit = () => {
    validateEmailField()
    validatePasswordField()
    setDisabledSubmit(true)
    setSubmitSignin(true)
  }

  useEffect(() => {
    if (submitSignin) {
      if (emailInputValidationMsg !== '') {
        emailInputRef.current!.focus()
      } else if (passwordInputValidationMsg !== '') {
        passwordInputRef.current!.focus()
      } else {
        setDisabledSubmit(true)
        apiClient
          .post('/users/set_new_primary_email', {
            new_email: emailInputVal.trim(),
            password: passwordInputVal,
          })
          .then((res) => {
            const successMsg =
              user.email === emailInputVal.trim()
                ? 'Your account has been successfully updated.'
                : 'Your request to update your account was processed successfully, but we need to verify your new email address. Please check your email and click the confirmation link to confirm your new email address.'

            setAlert({
              type: 'success',
              message: successMsg,
              shouldPersist: true
            })
            setUrl('/edit-account')
          })
          .catch((err) => {
            if (
              err.response.data.error &&
              err.response.data.error === 'Invalid e-mail address provided.'
            ) {
              setEmailInputValidationMsg(EMAIL_NO_ACCENTS_ERROR_MESSAGE)
            }
            if (
              err.response.data.error &&
              err.response.data.error === 'The password provided is not valid.'
            ) {
              setPasswordInputValidationMsg(
                'Your password must match your current password'
              )
            }
          })
      }
      setSubmitSignin(false)
    }
  }, [submitSignin])

  const handleEnterDown = (e: any) => {
    if (e.key === 'Enter' && !disabledSubmit) {
      handleSubmit()
    }
  }

  const handleEmailInput = (e: any) => {
    if (emailInputValidationMsg !== '') setEmailInputValidationMsg('')
    if (disabledSubmit) setDisabledSubmit(false)
    setEmailInputVal(e.target.value)
  }

  const handlePasswordInput = (e: any) => {
    if (passwordInputValidationMsg !== '') setPasswordInputValidationMsg('')
    if (disabledSubmit) setDisabledSubmit(false)
    setpasswordInputVal(e.target.value)
  }

  return (
    <div className="card" data-testid="change-primary-email-container">
      <h1 className="card-header text-left ">Change Your Email</h1>
      <div className="mx-16 border border-y-2 border-gray-200 opacity-30"></div>
      <div className="card-body p-0 flex flex-col space-y-6 text-md my-4 mb-2">
        <p>
          You must provide your own email address below if you are the one who
          is filing a form online, submitting an online request, or tracking a
          case.
        </p>
        <ValidatedInput
          label="Email"
          required
          msg={emailInputValidationMsg}
          onChange={handleEmailInput}
          value={emailInputVal}
          type="text"
          inputMode="email"
          ref={emailInputRef}
          onKeyDown={handleEnterDown}
        />
        <div className="pb-6">
          <ValidatedInput
            label="Current Password"
            required
            msg={passwordInputValidationMsg}
            onChange={handlePasswordInput}
            value={passwordInputVal}
            type={pwdFieldType}
            ref={passwordInputRef}
            onKeyDown={handleEnterDown}
          />
          <div className="w-full flex justify-end mt-1">
            <Checkbox
              id="checkbox-show-password"
              ariaLabel={'show password checkbox'}
              label="Show Password"
              ref={showPasswordRef}
              onChange={handlePasswordCheckbox}
            />
          </div>
        </div>
      </div>
      <div className="button-container card-body px-0 flex flex-col space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0 ">
        <Button
          id="update-email-submitbtn"
          data-testid="test-update-email-submitbtn"
          text="Update Email"
          className="max-w-full px-3 py-2 text-md"
          disabled={disabledSubmit}
          onClick={handleSubmit}
        />
        <Button
          id="cancel-btn"
          data-testid="test-cancel-btn"
          text="Cancel"
          type="cancel"
          className="max-w-full text-md"
          onClick={() => setUrl('/edit-account')}
        />
      </div>
    </div>
  )
}

export default ChangePrimaryEmail
