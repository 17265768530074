export interface UserProps {
  lock_expires_at?: string
  seconds_remaining?: number
  cis_uuid: string
  two_factor_method: Array<string>
  authentication_state: string
  reset_state?: string
  ial_state?: string
  email: string
  mobile: string | null
  recovery_email?: string | null
  unconfirmed_mobile?: string
  unconfirmed_two_factor_method?: string
  backup_code?: string
  account_type: string | null
  second_factor_strategy: string | null
  questions?: Array<QuestionProps> | null
  before_dashboard?: string | null
  msg?: string
  lg_email?: string
  lg_uuid?: string
  linked_lg_email?: string
  lg_account_linked?: string
  account_deletion_state?: string
  login_dot_gov_linked_email?: string | undefined | null
  force_redirection?: string | null
}

export interface AlertProps {
  type: string
  message: string | object
  shouldPersist?: boolean
}

export const defaultAlert = {
  type: '',
  message: '',
  shouldPersist: false
}
export interface QuestionProps {
  id: number
  question: string
}

export const testAlert = {
  type: 'success',
  message: 'This is a success',
}

export const testAlertError = {
  type: 'error',
  message: 'This is a failure',
}

export const testUser = {
  cis_uuid: 'abcde',
  two_factor_method: ['App'],
  authentication_state: 'awaiting_two_factor',
  email: 'test_email_com',
  mobile: '+1 (222) 333-4444',
  unconfirmed_mobile: '+1 (222) 333-4444',
  unconfirmed_two_factor_method: 'Mobile',
  account_type: null,
  second_factor_strategy: null,
  questions: [],
  before_dashboard: '',
}

export const testUser_Mobile = {
  cis_uuid: 'abcde',
  two_factor_method: ['Mobile'],
  authentication_state: 'fully_signed_in',
  email: 'test_email_com',
  mobile: '+1 (222) 333-4444',
  account_type: null,
  second_factor_strategy: null,
  questions: [],
  before_dashboard: '',
}

export const defaultUser = {
  cis_uuid: '',
  two_factor_method: [''],
  authentication_state: '',
  email: '',
  mobile: '',
  unconfirmed_mobile: '',
  unconfirmed_two_factor_method: 's',
  account_type: null,
  second_factor_strategy: null,
  questions: [],
  before_dashboard: '',
  ial_state: 'unknown',
  force_redirection: '',
  msg: '',
}

export interface StateProps {
  user: UserProps
  setUser: Function
  alert: AlertProps
  setAlert: Function
  clearAlert: Function
  clearPersistentAlert: Function
  token: string
  setToken: Function
  showAnniversaryLogo: boolean
  setShowAnniversaryLogo: Function
}

export const initialState: StateProps = {
  user: defaultUser,
  setUser: () => { },
  alert: defaultAlert,
  setAlert: () => { },
  clearAlert: () => { },
  clearPersistentAlert: () => { },
  token: '',
  setToken: () => { },
  showAnniversaryLogo: false,
  setShowAnniversaryLogo: () => { },
}

export const testInitialState: StateProps = {
  user: testUser,
  setUser: () => { },
  alert: testAlert,
  setAlert: () => { },
  clearAlert: () => { },
  clearPersistentAlert: () => { },
  token: '',
  setToken: () => { },
  showAnniversaryLogo: false,
  setShowAnniversaryLogo: () => { },
}
