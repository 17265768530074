import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Modal, SectionalAlert, ValidatedInput } from '../../baseComponents'
import { NewPageIcon } from '../../../assets/icons'
import { formatPhoneNumber, validateMobileFormat } from '../../../utils/utils'
import { configurationContext, myAccountContext, pathContext } from '../../../contexts/contexts'

import { useApiClient } from '../../../hooks/useApiClient'
import PageTitleHook from '../../../utils/PageTitleHook/PageTitleHook'

const AuthenticationOption = (props: any) => {
  const { id, value, title, description, children, selected, isChange2fa, previous2fa, handleChange, keyDownHandler } = props
  return (
    <div
      className={`radio-boxed grid grid-cols-12 cursor-pointer ${selected === value || (isChange2fa && previous2fa === value)
          ? 'bg-dhs_light_gray'
          : 'bg-gray'
        }`}
      aria-label={`${title}: ${description} ${selected === value || (isChange2fa && previous2fa === value)
          ? 'checked'
          : 'unchecked'
        }`}
      data-testid={`${id}-option`}
      tabIndex={0}
      onClick={() => handleChange(value)}
      onKeyDown={(e) => keyDownHandler(value, e)}
    >
      <input
        className="h-4 mt-2 cursor-pointer display-none mx-auto"
        data-testid={`${id}-radio-btn`}
        tabIndex={-1}
        id={id}
        type="radio"
        value={value}
        checked={selected === value || (isChange2fa && previous2fa === value)}
        readOnly
      />
      <label htmlFor={id} className="inline-flex col-span-11 cursor-pointer">
        <div>
          <h2 id={`${id}-title`} className="text-xl">
            {title}
          </h2>
          {children || (
            <p id={`${id}-desc`} className="text-md">
              {description}
            </p>
          )}
        </div>
      </label>
    </div>
  )
}

const Select2fa = (props: any) => {
  const { type } = props

  const { configuration } = useContext(configurationContext)
  const { mobileView } = configuration

  let nameSpace = ''

  switch (type) {
    case 'edit':
      nameSpace = 'edit-account'
      break
    case 'account-creation':
      nameSpace = 'account-creation'
      break
    default:
      break
  }

  PageTitleHook('USCIS Online Account | Two-step verification method preference')
  const { user, setUser, alert, setAlert, clearAlert } = useContext(myAccountContext)
  const { setUrl, userState, setUserState } = useContext(pathContext)
  const [isChange2fa, setisChange2fa] = useState(false)
  const [previous2fa, setprevious2fa] = useState('')
  const [selected, setSelected] = useState<string>(user.two_factor_method[0] || '')
  const [showAppModal, setshowAppModal] = useState(false)
  const [smsInputVal, setSmsInputVal] = useState('')
  const [smsInputValidationMsg, setSmsInputValidationMsg] = useState('')
  const [disabledSubmit, setDisabledSubmit] = useState(false)

  const smsInputRef = useRef<HTMLInputElement>(null)
  const sectionalAlertRef = useRef<HTMLDivElement>(null)

  const apiClient = useApiClient()

  const handleChange = (value: string) => {
    if (alert.message) clearAlert()
    if (disabledSubmit) setDisabledSubmit(false)

    setSelected(value)
    if (value === 'Mobile') {
      setSmsInputVal(formatPhoneNumber(user.mobile))
    }
  }

  const keyDownHandler = (value: string, e: any) => {
    if (e.key === ' ') {
      e.preventDefault()
      setSelected(value)
    }
  }

  const handleSubmit = () => {
    if (selected === '') return
    let selectedChoice: string | undefined = ''

    if (previous2fa !== '') {
      setSelected(previous2fa)
      selectedChoice = previous2fa
    } else {
      selectedChoice = selected
    }

    setDisabledSubmit(true)

    const path = `/users/${isChange2fa ? 'update_second_factor_method' : 'set_second_factor_method'
      }`

    if (selectedChoice === 'Mobile') {
      let val = !!smsInputVal ? smsInputVal.match(/[0-9]/g)?.join('') : ''
      const validationMsg = validateMobileFormat(val)
      setSmsInputValidationMsg(validationMsg)
      if (validationMsg !== '' && smsInputRef.current) {
        smsInputRef.current!.focus()
        return
      }
      apiClient
        .post(path, { method_name: 'Mobile', mobile: val })
        .then((res) => {
          setAlert({
            message: 'Your two-step verification method has been set. Please confirm that it works.',
            type: 'success',
            shouldPersist: true
          })
          setUser(res.data)
          setUrl(
            `/${nameSpace}/auth`
          )
        })
        .catch((err) => {
          setAlert({
            message: 'Something went wrong. Please try again',
            type: 'error',
            shouldPersist: true
          })
        })
    }

    if (selectedChoice === 'Email') {
      apiClient
        .post(path, { method_name: 'Email' })
        .then((res) => {
          setAlert({
            message: 'Your two-step verification method has been set. Please confirm that it works.',
            type: 'success',
            shouldPersist: true
          })
          setUser(res.data)
          setUrl(
            `/${nameSpace}/auth`
          )
        })
        .catch((err) => {
          setAlert({
            message: 'Something went wrong. Please try again',
            type: 'error',
            shouldPersist: true
          })
        })
    }

    if (selectedChoice === 'App') {
      setAlert({
        message: 'Your two-step verification method has been set.',
        type: 'success',
        shouldPersist: true
      })
      setUser({ ...user, unconfirmed_two_factor_method: 'App' })
      setUrl(
        `/${nameSpace}/totp`
      )
    }
  }

  const handleModalCancel = () => {
    setshowAppModal(false)
  }

  const handleEnterDown = (e: any) => {
    if (e.key === "Enter") {
      handleSubmit()
    }
  }

  const handleMobileInput = (e: any) => {
    if (alert.message !== '') clearAlert()
    if (smsInputValidationMsg !== '') setSmsInputValidationMsg('')
    if (disabledSubmit) setDisabledSubmit(false)
    const value = e.target.value
    if (value === '(0__) ___-____' || value === '(1__) ___-____') {
      setSmsInputVal('')
    } else {
      setSmsInputVal(formatPhoneNumber(e.target.value))
    }
  }

  const handleReturn = (e: any) => {
    setUrl('/edit-account')
  }

  useEffect(() => {
    if (alert.message !== '') {
      sectionalAlertRef.current!.focus()
    }
  }, [alert])


  useEffect(() => {
    setprevious2fa('')
  }, [selected])

  useEffect(() => {
    if (user["authentication_state"] === 'fully_signed_in' && user["two_factor_method"].length !== 0) {
      setSelected(user.two_factor_method[0])
      setisChange2fa(true)
      setprevious2fa(user["two_factor_method"][0])
      if (user["two_factor_method"][0] === 'Mobile' && user.mobile !== '') {
        setSmsInputVal(formatPhoneNumber(user.mobile))
      }
    }
    if (userState !== 'edit') {
      setUserState('selecting-2fa')
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <div className="card mx-auto" data-testid="select-2fa-container">
      <Modal
        title="What is an Authentication App?"
        visible={showAppModal}
        onCancel={handleModalCancel}
        onOk={handleModalCancel}
        info={false}
        hideCancelButton={true}
        hideOkButton={true}
      >
        <div className="flex flex-col space-y-5">
          <p>
            Authentication apps generate security codes for signing into sites
            that require a high level of security. You can use these apps to get
            security codes even if you don’t have an internet connection or
            mobile device.
          </p>
          <p>
            If you choose to utilize this option for setting up a two-step
            verification on your account, you will need to download and install
            an authentication app on your device. You can use most Time-Based,
            One-Time Password (TOTP) apps.{' '}
          </p>

          <div>
            <p>Android Options:</p>
            <ul className="pl-8 list-disc flex flex-col space-y-2">
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://play.google.com/store/apps/details?id=com.authy.authy`}
                  className="nav-link px-0 flex items-center mr-auto pt-1"
                >
                  Authy{' '}
                  {
                    <div className="ml-2">
                      <NewPageIcon />
                    </div>
                  }
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en`}
                  className="nav-link px-0 flex items-center mr-auto"
                >
                  Google Authenticator{' '}
                  {
                    <div className="ml-2">
                      <NewPageIcon />
                    </div>
                  }
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://play.google.com/store/apps/details?id=com.azure.authenticator`}
                  className="nav-link px-0 flex items-center mr-auto"
                >
                  Microsoft Authenticator{' '}
                  {
                    <div className="ml-2">
                      <NewPageIcon />
                    </div>
                  }
                </a>
              </li>
            </ul>
          </div>
          <div>
            <p>iOS Options:</p>
            <ul className="pl-8 list-disc flex flex-col space-y-2">
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://apps.apple.com/us/app/authy/id494168017`}
                  className="nav-link px-0 flex items-center mr-auto pt-1"
                >
                  Authy{' '}
                  {
                    <div className="ml-2">
                      <NewPageIcon />
                    </div>
                  }
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://apps.apple.com/us/app/google-authenticator/id388497605`}
                  className="nav-link px-0 flex items-center mr-auto"
                >
                  Google Authenticator{' '}
                  {
                    <div className="ml-2">
                      <NewPageIcon />
                    </div>
                  }
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://apps.apple.com/app/microsoft-authenticator/id983156458`}
                  className="nav-link px-0 flex items-center mr-auto"
                >
                  Microsoft Authenticator{' '}
                  {
                    <div className="ml-2">
                      <NewPageIcon />
                    </div>
                  }
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Modal>
      <h1 className="card-header">Two-Step Verification Method</h1>

      <div className="card-body">
        {alert.message !== '' && (
          <div className="py-1 mb-8" ref={sectionalAlertRef} tabIndex={0}>
            <SectionalAlert type={alert.type} data-testid="sectional-alert">
              {alert.message}
            </SectionalAlert>
          </div>
        )}
        <div className="font-default">
          Every time you log in, you will be given a verification code to use
          during the sign in process. What is your preferred method to receive
          your verification code?
        </div>

        <AuthenticationOption
          id="app"
          value="App"
          title="Authentication App"
          selected={selected}
          isChange2fa={isChange2fa}
          previous2fa={previous2fa}
          handleChange={handleChange}
          keyDownHandler={keyDownHandler}
          description="Retrieve codes from an authentication app (such as Authy or Google Authenticator) on your mobile device."
        ></AuthenticationOption>

        <AuthenticationOption
          id="mobile"
          value="Mobile"
          title="SMS Text Message"
          selected={selected}
          isChange2fa={isChange2fa}
          previous2fa={previous2fa}
          handleChange={handleChange}
          keyDownHandler={keyDownHandler}
          description="Receive a text message to your mobile device when signing in."
        ></AuthenticationOption>

        <AuthenticationOption
          id="email"
          value="Email"
          title="Email"
          selected={selected}
          isChange2fa={isChange2fa}
          previous2fa={previous2fa}
          handleChange={handleChange}
          keyDownHandler={keyDownHandler}
          description="Receive an email when signing in."
        ></AuthenticationOption>

        {(selected === 'App' || (isChange2fa && previous2fa === 'App')) && (
          <div className="flex flex-col space-y-3 ">
            <p>
              You can use most Time-Based, One-Time Password (TOTP) applications
              for added security, which will ask you to enter a unique
              verification code generated by the selected application on your
              mobile device.{' '}
            </p>
            <p>
              USCIS advises that you read the privacy policies of any
              application you use, especially if you share any personal
              information. USCIS is not responsible for the information
              collection practices of non-USCIS applications.
            </p>
          </div>
        )}
        {(selected === 'Mobile' ||
          (isChange2fa && previous2fa === 'Mobile')) && (
            <div className="flex flex-col space-y-3">
              <p>
                If you select to receive text messaging notification to a U.S.
                mobile phone number listed in your account and accept these terms
                and conditions, you acknowledge that Standard Messaging Rates or
                other charges related to these notifications may apply.
              </p>
              <ValidatedInput
                label="Mobile Number"
                required
                msg={smsInputValidationMsg}
                onChange={handleMobileInput}
                value={!!smsInputVal ? smsInputVal : ''}
                type="text"
                inputMode="numeric"
                ref={smsInputRef}
                onKeyDown={handleEnterDown}
                mobile={true}
              />
            </div>
          )}
        <div className="button-container flex-nowrap mt-6">
          <Button
            id="submit-2fa-btn"
            data-testid="submit-2fa-btn"
            text="Submit"
            disabled={disabledSubmit}
            onClick={handleSubmit}
            ariaDisabled={selected === ''}
          />
          {selected === 'App' && !mobileView && (
            <Button
              id="appmodal-2fa-btn"
              data-testid="test-appmodal-2fa-btn"
              text="What is an Authentication App?"
              className={`px-3 font-bold sm:w-48 sm:text-sm ${isChange2fa ? 'sm:leading-none' : ''
                }`}
              type="secondary"
              onClick={(e) => setshowAppModal(true)}
            />
          )}
          {isChange2fa && (
            <Button
              id="select-2fa-cancel-btn"
              data-testid="select-2fa-cancel-btn"
              text="Cancel"
              type="cancel"
              onClick={handleReturn}
            />
          )}
        </div>
      </div>
    </div>
  )
}
export default Select2fa