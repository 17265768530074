import React from 'react'

export const FirstLogo = () => {
    return (
        <svg role='img' aria-label='first logo' id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="127.815" height="36.175" viewBox="0 0 127.815 36.175">
            <g id="Layer_1" data-name="Layer 1">
                <path id="Path_276774" data-name="Path 276774" d="M1.833,2.01,0,2.3,3.473,20.507Z" transform="translate(0 0.414)" fill="#fff"/>
                <path id="Path_276775" data-name="Path 276775" d="M6.424.29,3.808.35,3.47,29.506h.615Z" transform="translate(0.714 0.06)" fill="#fff"/>
                <path id="Path_276776" data-name="Path 276776" d="M10.4,1.628H27.558L27.884,0H8.495L4.19,32.725l.772-.024Z" transform="translate(0.862)" fill="#fff"/>
                <path id="Path_276777" data-name="Path 276777" d="M4.83,35.073,11.39,2.42H30.682L29.621,8.184H17.093L15.537,16.2H26.161l-1.1,5.764H14.344l-2.6,13.107Z" transform="translate(0.994 0.498)" fill="#fff"/>
                <path id="Path_276778" data-name="Path 276778" d="M25.76,35.073,32.32,2.42h6.861L32.669,35.073Z" transform="translate(5.301 0.498)" fill="#fff"/>
                <path id="Path_276779" data-name="Path 276779" d="M38,35.073,44.523,2.42H54.736a20.8,20.8,0,0,1,4.461.446,10.081,10.081,0,0,1,3.533,1.459,7.162,7.162,0,0,1,2.327,2.6,8.127,8.127,0,0,1,.856,3.859,12.721,12.721,0,0,1-.627,4.136,10.707,10.707,0,0,1-4.377,5.631,14.277,14.277,0,0,1-3.328,1.6l5.076,12.914H55.424l-4.16-11.962H47.224L44.813,35.085ZM48.334,17.589h3.461a8.308,8.308,0,0,0,5.5-1.519,5.33,5.33,0,0,0,1.857-4.281,3.3,3.3,0,0,0-1.4-3,7.536,7.536,0,0,0-4.076-.9H50.239Z" transform="translate(7.82 0.498)" fill="#fff"/>
                <path id="Path_276780" data-name="Path 276780" d="M72.862,35.769a14.7,14.7,0,0,1-6.234-1.4,14.168,14.168,0,0,1-5.438-4.7L66.1,25.809a9.719,9.719,0,0,0,3.232,3,8.007,8.007,0,0,0,4.027,1.1,5.426,5.426,0,0,0,3.4-1,3.28,3.28,0,0,0,1.3-2.785,2.617,2.617,0,0,0-1.025-2.134,30.219,30.219,0,0,0-2.81-1.893l-3.1-1.905a11.865,11.865,0,0,1-3.557-3.28,8.441,8.441,0,0,1-1.3-4.823,8.766,8.766,0,0,1,.9-3.931,10.575,10.575,0,0,1,2.412-3.207A11.443,11.443,0,0,1,73.2,2.767a12.564,12.564,0,0,1,4.51-.8,11.491,11.491,0,0,1,5.51,1.242A14.94,14.94,0,0,1,87.681,6.83l-4.353,4.1a13.975,13.975,0,0,0-2.653-2.182,5.92,5.92,0,0,0-3.207-.88,4.293,4.293,0,0,0-3.075,1.025,3.3,3.3,0,0,0-1.073,2.484,2.412,2.412,0,0,0,1.1,2.05q1.1.8,3,2l3.207,1.953a10.2,10.2,0,0,1,3.485,3.183,8.585,8.585,0,0,1,1.073,4.582,9.9,9.9,0,0,1-3.352,7.536,11.817,11.817,0,0,1-3.883,2.255A14.831,14.831,0,0,1,72.862,35.769Z" transform="translate(12.593 0.405)" fill="#fff"/>
                <path id="Path_276781" data-name="Path 276781" d="M88.444,35.073,93.761,8.232H85.2L86.3,2.42h23.983l-1.206,5.812h-8.441L95.3,35.073Z" transform="translate(17.534 0.498)" fill="#fff"/>
            </g>
        </svg>

    )
} 

