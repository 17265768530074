import React, { FunctionComponent } from "react";
import { OfficialLogoAndLink } from '../OfficialLogoAndLink';
import { FooterExternalLinks } from '../FooterExternalLinks';


export const FooterBottomLogo: FunctionComponent = () => {
  return (
    <div data-testid="footer-bottom-logo" className='flex flex-col gap-2 pb-5 sm:w-2/3 mb-14 mb-5 sm:justify-center lg:w-full'>
      <OfficialLogoAndLink />
      <FooterExternalLinks />
    </div>
  );
};

export default FooterBottomLogo;